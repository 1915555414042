import React, { useState, useEffect } from "react"
import {
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material"
import "./layout.css"
import Avatar from "@mui/material/Avatar"
import { useTheme } from "@mui/material/styles"
import Badge from "@mui/material/Badge"
import { deepPurple } from "@mui/material/colors"
import { useSelector, useDispatch } from "react-redux"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import {
    setProfileImg,
    setOpenSide,
    setOpenDrawer
} from "../../redux/slices/navSlice"
import { useNavigate } from "react-router-dom"
import Notification from "../../components/common/Notification/Notification"
import { getAllNotification, getAllNotificationBG } from "../../services/index"
import MenuIcon from "@mui/icons-material/Menu"
import ProfileMenu from "./ProfileMenu"

function Header() {
    const profileImg = useSelector((state) => state.nav.profileImg)
    const user = JSON.parse(window.localStorage.getItem("user"))
    const userId = user?.userId
    const firstName = user?.firstName || ""
    const lastName = user?.lastName || ""
    const name = lastName === null ? firstName : firstName + " " + lastName
    const dispatch = useDispatch()
    const navigate = useNavigate() // eslint-disable-next-line
    const [pushNotificationCount, setpushNotificationCount] = useState(0)
    const theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.down("sm"))

    // every 5 minutes

    const [anchorMenu, setAnchorMenu] = React.useState(null)
    const [anchorProfile, setAnchorProfile] = React.useState(null)

    const open = Boolean(anchorMenu)
    const handleClick = (event) => {
        setAnchorMenu(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorMenu(null)
    }

    const openProfile = Boolean(anchorProfile)
    const handleProfileClick = (event) => {
        setAnchorProfile(event.currentTarget)
    }
    const handleProfileClose = () => {
        setAnchorProfile(null)
    }
    const handleSideOpen = () => {
        // ** setOpenDrawer is necessary, because if openDrawer is set to false in desktop mode, that
        // state persist in mobile screen too, because of that menu label is not visible in mobile screen, only icons
        // which is a bug
        dispatch(setOpenDrawer())
        dispatch(setOpenSide(true))
    }

    useEffect(() => {
        if (!isXs) {
            setAnchorProfile(null)
        }
    }, [isXs])

    useEffect(() => {
        const updatedProfilePhotoUrl =
            user?.profilePhotoUrl !== null
                ? `${user?.profilePhotoUrl}?${Date.now()}`
                : null
        dispatch(setProfileImg(updatedProfilePhotoUrl))
        initialNotifications()
        const interval = setInterval(() => {
            getNotificationsBG()
        }, 965000)
        //Clearing the interval
        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [])

    const initialNotifications = async () => {
        const response = await getAllNotification({
            userId: userId,
            pageNo: 0,
            pageSize: 3
        })
        if (response?.data?.totalUnreadCount) {
            await setpushNotificationCount(response?.data?.totalUnreadCount)
        }
    }
    const getNotificationsBG = async () => {
        const res = await getAllNotificationBG({
            userId: userId,
            pageSize: 3,
            pageNo: 0
        })
        if (res) {
            setpushNotificationCount(res?.data?.totalUnreadCount)
        }
    }

    return (
        <>
            {/** Header section for mobile display */}
            <Grid
                id="header-grid"
                sx={{
                    background: "white",
                    border: "1px solid #ededed",
                    padding: "15px 0px 15px 21px"
                }}
            >
                {/** Hamburger to display the sidebar drawer and Kairos logo */}
                <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontSize: "22px",
                                fontWeight: "500",
                                display: { xs: "flex", sm: "none" },
                                alignItems: { xs: "center", sm: "none" }
                            }}
                        >
                            <MenuIcon
                                onClick={handleSideOpen}
                                sx={{
                                    color: "#1e1e1e",
                                    marginRight: "8%",
                                    fontSize: "30px"
                                }}
                            />
                            <img
                                src={user?.entityLogo}
                                style={{
                                    width: "40px",
                                    objectFit: "contain",
                                    cursor: "pointer"
                                }}
                                alt="logoIcon"
                                onClick={() => {
                                    navigate("/dashboard")
                                }}
                            />
                        </Typography>
                    </Grid>

                    {/** Avatar, Name and Notifications */}
                    <Stack flexDirection="row" gap={1}>
                        <IconButton color="brand" onClick={handleClick}>
                            <Badge
                                badgeContent={pushNotificationCount}
                                max={99}
                                color="brand"
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                            >
                                <NotificationsNoneOutlinedIcon
                                    sx={{ fontSize: "32px" }}
                                />
                            </Badge>
                        </IconButton>
                        <IconButton onClick={handleProfileClick}>
                            <Avatar
                                alt={name[0]}
                                src={profileImg}
                                sx={{
                                    bgcolor: deepPurple[300]
                                }}
                            >
                                {name[0]}
                            </Avatar>
                        </IconButton>
                    </Stack>
                </Grid>

                {open && (
                    <Notification
                        onClick={() => {}}
                        onClose={handleMenuClose}
                        open={open}
                        setpushNotificationCount={setpushNotificationCount}
                        anchorEl={anchorMenu}
                    />
                )}
                {openProfile && (
                    <ProfileMenu
                        anchorEl={anchorProfile}
                        isOpen={openProfile}
                        close={handleProfileClose}
                    />
                )}
            </Grid>
        </>
    )
}

export default Header
