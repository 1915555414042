import { Typography } from "@mui/material"

const MarketPageTitleSection = ({ url }) => {
    return (
        <>
            <Typography sx={{ fontSize: "28px", fontWeight: 700, px: 2.5 }}>
                {url === "service"
                    ? "Service Offerings: Finance to Lifestyle"
                    : "Product Offerings: Finance to Lifestyle"}
            </Typography>
            <Typography variant="subtitle1" sx={{ px: 2.5, mb: 1 }}>
                From Wealth Management to Lifestyle Convenience – All in One
                Place
            </Typography>
        </>
    )
}
export default MarketPageTitleSection
