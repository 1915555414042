export const TABLE_ROWS_PER_PAGE = 7 // ** 8 because it is the max record that can fit screen without scroll

export const RESPONSIVE_MODES = {
    XL: "XL",
    LG: "LG",
    MD: "MD",
    SM: "SM"
}

export const REGEX = {
    mobileNumber: /^[0-9]{7,15}$/,
    postalCode: /^\d{1,8}$/,
    id: /^[\d\w-]+$/,
    alphaWithNoSpace: /^[A-Za-z]{1,28}$/,
    password:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]{8,}$/
}
