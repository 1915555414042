import { createSlice } from "@reduxjs/toolkit"

const catalogSlice = createSlice({
    name: "catalog",
    initialState: {
        financialAssetCategories: [],
        lifeStyleAssetCategories: [],
        financial: {
            assets: [],
            nonEmptyCategories: []
        },
        lifeStyle: {
            assets: [],
            nonEmptyCategories: []
        },
        nonEmptyCategories:
            [] /* will hold list of categories, for which we have assets */
    },
    reducers: {
        setAssetCategories: (state, action) => {
            state.financialAssetCategories =
                action.payload.financialAssetCategories
            state.lifeStyleAssetCategories =
                action.payload.lifeStyleAssetCategories
        },
        setFinancialCatalog: (state, action) => {
            const payload = {
                assets: action.payload.assets,
                nonEmptyCategories: action.payload.nonEmptyCategories
            }
            state.financial = payload
        },
        setLifeStyleCatalog: (state, action) => {
            const payload = {
                assets: action.payload.assets,
                nonEmptyCategories: action.payload.nonEmptyCategories
            }
            state.lifeStyle = payload
        }
    }
})

export const { setAssetCategories, setFinancialCatalog, setLifeStyleCatalog } =
    catalogSlice.actions

export default catalogSlice.reducer
