import { useState } from "react"
import { addFeedback } from "services"
import { Typography, TextField, Rating, Button, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import { StarRounded, StarOutlineRounded } from "@mui/icons-material"
import { useSnackbar } from "components/common/snackbar/SnackbarProvider"
import { DialogWrapper } from "components/common/Ui/Dialog"
import { LoadingButton } from "@mui/lab"
import { getLoggedInRole, getLoggedInUserId } from "constant/helpersUtility"

const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
        color: "#FFDFOO"
    },
    "& .MuiRating-iconHover": {
        color: "#FFDFOO"
    }
})

const FeedbackModal = ({ isOpen, close }) => {
    const [rating, setRating] = useState(0)
    const [feedback, setFeedback] = useState("")
    const [warnings, setWarnings] = useState(false)
    const { onError, onSuccess } = useSnackbar()
    const [role] = useState(getLoggedInRole)
    const [userId] = useState(getLoggedInUserId)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = (e) => {
        setIsLoading(true)
        e.preventDefault()
        const obj = {
            userId: userId,
            rating: rating,
            feedback: feedback,
            submit: true
        }
        if (rating > 0) {
            addFeedback(
                role === "HNI" ? "clientfeedback" : "customerfeedback",
                obj
            ).then((res) => {
                close()
                if (res?.data?.status === true) {
                    onSuccess(res?.data?.statusMessage)
                } else {
                    onError(res?.data?.errorMessage)
                }
            }).finally(() => {
                    setIsLoading(false)
                })
        } else {
            setWarnings(true)
        }
    }
    const handleRating = (_, newVal) => {
        setRating(newVal)
        if (newVal > 0) {
            setWarnings(false)
        }
    }

    return (
        <DialogWrapper
            maxWidth="sm"
            showModal={isOpen}
            closeModal={close}
            title="Send us your Feedback!"
        >
            <Typography
                id="modal-modal-title"
                sx={{
                    fontSize: "13px",
                    fontFamily: "Mona Sans"
                }}
            >
                Rate your experience with Kairos so far.
            </Typography>
            <form onSubmit={handleSubmit}>
                <StyledRating
                    name="customized-color"
                    value={rating}
                    onChange={(e, newVal) => handleRating(e, newVal)}
                    precision={1}
                    icon={<StarRounded fontSize="large" />}
                    emptyIcon={<StarOutlineRounded fontSize="large" />}
                />
                {warnings && (
                    <p
                        style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: 600,
                            fontFamily: "Mona Sans",
                            marginTop: "-3px",
                            marginLeft: "10px"
                        }}
                    >
                        This field is required
                    </p>
                )}
                <TextField
                    sx={{ mt: 2 }}
                    label="Feedback"
                    id="outlined-multiline-static"
                    multiline
                    name="feedback"
                    rows={4}
                    inputProps={{
                        maxLength: 1000
                    }}
                    placeholder="Type your enquiry here"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    fullWidth
                    color="brand"
                />

                <Stack
                    flexDirection="row"
                    gap={1}
                    justifyContent="flex-end"
                    mt={4}
                >
                    <Button
                        disabled={isLoading}
                        onClick={close}
                        color="brand"
                        sx={{ borderRadius: 99 }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        color="brand"
                        variant="contained"
                        sx={{ borderRadius: 99 }}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            </form>
        </DialogWrapper>
    )
}
export default FeedbackModal
