import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { Divider, Typography } from "@mui/material"
import { ReactComponent as ExpandIcon } from "theme/images/AccordianExpandIcon.svg"
const AccordionWrapper = ({ defaultExpanded = false, title, children }) => {
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            sx={{
                "&:before": {
                    display: "none"
                },
                boxShadow: "none",
                bgcolor: "transparent",
                ".MuiAccordionSummary-root": {
                    px: 0
                },
                ".MuiAccordionDetails-root": {
                    px: 0
                },
                "&.MuiAccordion-root.Mui-expanded": {
                    marginTop: 0
                }
            }}
        >
            <AccordionSummary
                sx={{ alignItems: "center", flexDirection: "row-reverse" }}
                expandIcon={<ExpandIcon />}
            >
                <Typography ml={1} variant="h4" fontWeight={500}>
                    {title}
                </Typography>
                <Divider
                    fullWidth
                    sx={{
                        flexGrow: 1,
                        my: "auto",
                        ml: 3
                    }}
                    orientation="horizontal"
                />
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    )
}

export default AccordionWrapper
