import React, { useCallback, useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import {
    setTerminalHistory,
    setSelectedTerminalResponse,
    setAgentType
} from "../../../../redux/slices/ChatGptSlice"

import { Typography, Grid, CircularProgress, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import RefreshIcon from "@mui/icons-material/Refresh"
import { toast } from "react-toastify"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

const Item = styled(Grid)(({ theme }) => ({
    backgroundColor: "gray.200",
    ...theme.typography.body2,
    textAlign: "left",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "8px"
}))

const env = process.env.REACT_APP_DOMAIN_NAME
const base_url = "https://gaia.kairoswealth.com/"
const url = base_url + "get_terminal/"

export default function SidebarAgent() {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { terminalHistory, triggerTerminalHistOfAgent } = useSelector((state) => state.chatGpt)
    const agentType = useSelector((state) => state.chatGpt.agentType)

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [auth_header] = useState(() => {
        let auth_header = ""
        if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
            const user = window.localStorage.getItem("user")
            if (user) {
                auth_header = `Bearer ${JSON.parse(user).idToken}`
            }
        }
        return auth_header
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let headers = useCallback(
        {
            authorization: auth_header,
            env,
            "content-type": "application/json"
        },
        [auth_header, env]
    )

    const handleRefresh = useCallback(() => {
        setIsRefreshing(true)
        fetch(url, { method: "GET", headers: headers, credentials: "include" })
            .then((response) => {
                return response.json()
            })
            .then((res_json) => {
                let json_response = JSON.parse(res_json)

                // Convert the object into an array of key-value pairs
                let keyValueArray = Object.entries(json_response)

                // Sort the array based on the startdate field of the first object in each child array
                keyValueArray.sort((a, b) => {
                    let dateA = new Date(a[1][0].startdate)
                    let dateB = new Date(b[1][0].startdate)
                    return dateB - dateA // sort in ascending order
                })

                // Convert the sorted array back into an object
                json_response = Object.fromEntries(keyValueArray)

                dispatch(setTerminalHistory(json_response))

                setIsRefreshing(false)
            })
            .catch((error) => {
                console.error("Error refreshing data:", error)
                toast.error(
                    "Error refreshing data, please contact your administrator. Error Code 708"
                )
                setIsRefreshing(false)
            })
    }, [dispatch, headers])

    const handleDelete = (execution) => {
        setIsRefreshing(true)
        fetch(base_url + "terminal_event/?execution_id=" + execution, {
            method: "DELETE",
            headers: headers,
            credentials: "include"
        })
            .then((response) => {
                handleRefresh()
                setIsRefreshing(false)
            })
            .catch((error) => {
                console.error("Error refreshing data:", error)
                toast.error(
                    "Error deleting data, please contact your administrator. Error Code 709"
                )
                setIsRefreshing(false)
            })
        console.log({ execution })
    }

    useEffect(() => {
        fetch(url, { method: "GET", headers: headers, credentials: "include" })
            .then((response) => {
                return response.json()
            })
            .then((res_json) => {
                const originalObject = JSON.parse(res_json)
                // Convert the object into an array of key-value pairs
                const keyValueArray = Object.entries(originalObject)

                // Reverse the order of the array
                const reversedArray = keyValueArray.reverse()

                // Reconstruct the object from the reversed array
                const reversedObject = Object.fromEntries(reversedArray)

                dispatch(setTerminalHistory(reversedObject))
            })
            .catch((error) => console.log(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        //NOTE:Waiting for 20s to refresh the list because it takes more than 15s to update the db
        // We might not want auto refresh may be user can do manually
        setTimeout(() => {
            handleRefresh()
        }, [20000])
    }, [triggerTerminalHistOfAgent, handleRefresh])

    return (
        <Grid
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{ marginX: 2, height: "100%" }}
        >
            <Grid
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                sx={{ marginBottom: 2, color: "#fff" }}
            >
                <Typography
                    sx={{
                        paddingX: 2,
                        paddingY: 0.5,
                        border: "1px solid gray",
                        borderRadius: "12px 0 0 12px",
                        background: agentType === "finance" ? "#6442C4" : "#FFFFFF",
                        color: agentType === "finance" ? "#FFFFFF" : "#000000",
                        cursor: "pointer",
                        width: "50%",
                        textAlign: "center"
                    }}
                    onClick={() => dispatch(setAgentType("finance"))}
                >
                    {t("Finance API")}
                </Typography>
                <Typography
                    sx={{
                        paddingX: 2,
                        paddingY: 0.5,
                        border: "1px solid gray",
                        borderRadius: "0 12px 12px 0",
                        borderLeft: "none",
                        background: agentType === "web" ? "#6442C4" : "#FFFFFF",
                        color: agentType === "web" ? "#FFFFFF" : "#000000",
                        cursor: "pointer",
                        width: "50%",
                        textAlign: "center"
                    }}
                    onClick={() => dispatch(setAgentType("web"))}
                >
                    {t("Web Research")}
                </Typography>
            </Grid>
            <Grid
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Typography>{t("Gaia Agent History")}</Typography>
                <Grid display="flex" flexDirection="row">
                    {isRefreshing ? (
                        <CircularProgress
                            size="1rem"
                            color="inherit"
                            sx={{ marginLeft: 2, marginTop: 0.5 }}
                        />
                    ) : null}
                    <RefreshIcon
                        onClick={handleRefresh}
                        sx={{ cursor: "pointer" }}
                    />
                </Grid>
            </Grid>
            <Typography variant="caption" mb={2}>
                {t(
                    "Click refresh button to update status of agents in progress"
                )}
            </Typography>
            {/** Show the history */}
            {
                Object.keys(terminalHistory).length > 0 ? (
                    <Grid sx={{ height: "100%" }}>
                        {Object.entries(terminalHistory).map((execution, index) => {
                            //console.log({execution}); // this is the object for each execution. [0] is execution_id, [1] is event line
                            let question = ""
                            let isCompleted = false
                            execution[1].forEach((event_line) => {
                                if (event_line.type === "QUESTION") {
                                    question = event_line.text
                                }
                                if (event_line.type === "VERDICT") {
                                    isCompleted = true
                                }
                            })
                            return (
                                <Item
                                    component={motion.div}
                                    whileHover={{
                                        translateX: 5,
                                        transition: { duration: 0.3 }
                                    }}
                                    key={index}
                                    sx={{
                                        border: "1px solid gray",
                                        marginBottom: 1
                                    }}
                                >
                                    <Grid
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Tooltip
                                            title={isCompleted ? "" : "In Progress"}
                                        >
                                            <Grid
                                                sx={{ cursor: "pointer" }}
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="flex-start"
                                                onClick={() => {
                                                    dispatch(
                                                        setSelectedTerminalResponse(
                                                            execution
                                                        )
                                                    )
                                                }}
                                            >
                                                <Grid
                                                    id={"bookmarkstatus" + index}
                                                    sx={{
                                                        width: "3px",
                                                        minWidth: "3px",
                                                        minHeight: "100%",
                                                        backgroundColor: isCompleted
                                                            ? "#008000"
                                                            : "#FFFF00",
                                                        marginRight: 1
                                                    }}
                                                >
                                                    &nbsp;
                                                </Grid>
                                                {question}
                                            </Grid>
                                        </Tooltip>
                                        <DeleteOutlineIcon
                                            fontSize="small"
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                            onClick={() =>
                                                handleDelete(execution[0])
                                            }
                                        />
                                    </Grid>
                                </Item>
                            )
                        })}
                    </Grid>
                ) : (
                    <></>
                )
            }
        </Grid >
    )
}
