import { useState, useEffect } from "react"
import { Grid, Typography, Box, useMediaQuery, Tooltip } from "@mui/material"
import "./layout.css"
import Avatar from "@mui/material/Avatar"
import { useSelector, useDispatch } from "react-redux"
import { setProfileImg, setCloseDrawer } from "../../redux/slices/navSlice"
import Notification from "../common/Notification/Notification"
import { getAllNotification, getAllNotificationBG } from "../../services/index"
import SideBarMenuLinnear from "../../theme/images/NewSidebarIcons/SideBarMenuLinear.svg"
import { getLoggedInRole, getLoggedInUserInfo } from "constant/helpersUtility"
import ProfileMenu from "./ProfileMenu"
import { useTheme } from "@mui/material/styles"
import VerifyButtonWithLabel from "./GetVerified/VerifyButtonWithLabel"
import VerifyButtonIcon from "./GetVerified/VerifyButtonIcon"

function BottomSideBar() {
    // ** redux
    const { profileImg, openDrawer: isDrawerOpen } = useSelector(
        (state) => state.nav
    )
    const dispatch = useDispatch()

    // ** state
    const [user] = useState(getLoggedInUserInfo)
    const [role] = useState(getLoggedInRole)
    const [pushNotificationCount, setpushNotificationCount] = useState(0)
    const [anchorMenu, setAnchorMenu] = useState(null)
    const [anchorProfile, setAnchorProfile] = useState(null)
    const theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.down("sm"))

    const userId = user.userId

    const name = `${user.firstName} ${user.lastName || ""}`
    const open = Boolean(anchorMenu)
    const openProfile = Boolean(anchorProfile)
    const isLightInvestor = role === "LIGHT_INVESTOR"

    useEffect(() => {
        if (isXs) {
            setAnchorProfile(null)
        }
    }, [isXs])

    useEffect(() => {
        const getNotificationsBG = async () => {
            const res = await getAllNotificationBG({
                userId: userId,
                pageSize: 3,
                pageNo: 0
            })
            if (res) {
                setpushNotificationCount(res?.data?.totalUnreadCount)
            }
        }

        const interval = setInterval(() => {
            getNotificationsBG()
        }, 965000)
        return () => clearInterval(interval)
    }, [userId])

    useEffect(() => {
        const initialNotifications = async () => {
            const response = await getAllNotification({
                userId: userId,
                pageNo: 0,
                pageSize: 3
            })
            if (response?.data?.totalUnreadCount) {
                setpushNotificationCount(response?.data?.totalUnreadCount)
            }
        }

        initialNotifications()
        dispatch(setProfileImg(user?.profilePhotoUrl || ""))
    }, [user?.profilePhotoUrl, dispatch, userId])

    const handleClick = (event) => {
        // ** Current figma design instructed to close the sidebar when notifiction btn clicked
        dispatch(setCloseDrawer())
        setAnchorMenu(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorMenu(null)
    }
    const handleProfileClick = (event) => {
        setAnchorProfile(event.currentTarget)
    }
    const handleProfileClose = () => {
        setAnchorProfile(null)
    }

    return (
        <>
            <Grid
                sx={{
                    width: "100%",
                    background: { xs: "#171B24", sm: "white" },
                    borderTop: "1px solid #EBEBEB"
                }}
            >
                {isDrawerOpen && (
                    <Grid
                        container
                        sx={{ display: "flex", width: "100%", mt: "7px" }}
                    >
                        <Grid
                            item
                            container
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            sx={{ width: "100%", padding: "0px 0px 5px 21px" }}
                        >
                            {isDrawerOpen && (
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontFamily: "Mona Sans",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "16.8px",
                                            pt: "2px",
                                            textAlign: "left"
                                        }}
                                    >
                                        {"Notification"}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item sx={{ marginRight: "20px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#643DD6",
                                        cursor: "pointer",
                                        width: "24px",
                                        height: "24px",
                                        fontFamily: "Mona Sans",
                                        fontSize: "10px",
                                        fontWeight: 500,
                                        lineHeight: "15px",
                                        textAlign: "middle"
                                    }}
                                    onClick={handleClick}
                                >
                                    {pushNotificationCount <= 99
                                        ? pushNotificationCount
                                        : "99+"}
                                </Avatar>
                            </Grid>
                        </Grid>
                        {isLightInvestor && (
                            <Grid sx={{ width: "100%", mt: 1.5 }} item>
                                <VerifyButtonWithLabel />
                            </Grid>
                        )}
                    </Grid>
                )}
                {isDrawerOpen && (
                    <Grid
                        container
                        sx={{
                            borderTop: "1px solid #EBEBEB",
                            width: "100%",
                            height: "64px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0px 15px 15px 15px"
                        }}
                    >
                        <Grid item>
                            {profileImg ? (
                                <Avatar
                                    alt="Remy Sharp"
                                    src={profileImg}
                                    sx={{ width: "24px", height: "24px" }}
                                />
                            ) : (
                                <Avatar
                                    sx={{
                                        width: "24px",
                                        height: "24px",
                                        bgcolor: "#F6CD5B",
                                        fontFamily: "Mona Sans",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.8px",
                                        textAlign: "left",
                                        color: "black"
                                    }}
                                >
                                    {name[0]}
                                </Avatar>
                            )}
                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    fontFamily: " Mona Sans",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "16.8px",
                                    textAlign: "left",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "18ch"
                                }}
                            >
                                {name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            style={{ cursor: "pointer" }}
                            onClick={handleProfileClick}
                        >
                            <img src={SideBarMenuLinnear} alt="SideMenus"></img>
                        </Grid>
                    </Grid>
                )}
                {!isDrawerOpen && (
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                    >
                        <Grid
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ height: "30px" }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: "#643DD6",
                                    cursor: "pointer",
                                    width: "24px",
                                    height: "24px",
                                    mt: "3px",
                                    fontFamily: "Mona Sans",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                    lineHeight: "15px",
                                    textAlign: "middle"
                                }}
                                onClick={handleClick}
                            >
                                {pushNotificationCount <= 99
                                    ? pushNotificationCount
                                    : "99+"}
                            </Avatar>
                        </Grid>
                    </Grid>
                )}
                {!isDrawerOpen && isLightInvestor && (
                    <Box
                        sx={{
                            height: "30px",
                            my: 1,
                            display: "grid",
                            placeContent: "center"
                        }}
                    >
                        <Tooltip title="Get verified">
                            <VerifyButtonIcon />
                        </Tooltip>
                    </Box>
                )}
                {!isDrawerOpen && (
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ width: "100%", borderTop: "1px solid #EBEBEB" }}
                    >
                        <Grid
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ height: "64px", mt: "3px" }}
                        >
                            {profileImg ? (
                                <Avatar
                                    alt="Remy Sharp"
                                    src={profileImg}
                                    onClick={handleProfileClick}
                                    sx={{ width: "24px", height: "24px" }}
                                />
                            ) : (
                                <Avatar
                                    onClick={handleProfileClick}
                                    sx={{
                                        width: "24px",
                                        height: "24px",
                                        bgcolor: "#F6CD5B",
                                        fontFamily: "Mona Sans",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.8px",
                                        textAlign: "left",
                                        color: "black"
                                    }}
                                >
                                    {name[0]}
                                </Avatar>
                            )}
                        </Grid>
                    </Grid>
                )}
                {open && (
                    <Notification
                        onClick={() => {}}
                        onClose={handleMenuClose}
                        open={open}
                        setpushNotificationCount={setpushNotificationCount}
                        anchorEl={anchorMenu}
                    />
                )}
                <ProfileMenu
                    anchorEl={anchorProfile}
                    isOpen={openProfile}
                    close={handleProfileClose}
                />
            </Grid>

            {/* commented on 2025-02-07 because I see no purpose */}
            {/*<Grid
                sx={{
                    padding: "5px 7px ",
                    background: "#F7F7F7",
                    border: "1.1px solid #E0E0E0"
                }}
            >
                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        letterSpacing: "0.16px"
                    }}
                >
                    {text === "Payments" ? "My Transactions" : text}
                </div>
            </Grid>*/}

            {/** Feedback modal */}
        </>
    )
}

export default BottomSideBar
