import { Box, Typography } from "@mui/material"
import norecordimg from "theme/images/ProductCardsIcons/emptytransactions.svg"

export const NoRecords = () => {
    return (
        <Box sx={{ textAlign: "center", maxWidth: "100%", overflow: "hidden" }}>
            <img
                src={norecordimg}
                alt="No records visual"
                style={{ maxWidth: "100%", height: "auto" }}
            />
            <Typography variant="h6" mt={0.5}>
                No activities yet
            </Typography>
        </Box>
    )
}
