import { Box, Typography, Stack, IconButton } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { Formik, Form } from "formik"
import { PageLoader, TextFieldWrapper } from "components/common/Ui/Form"
import { useLightInvestorReg } from "./useLightInvestorReg"
import logo from "theme/images/NewSidebarIcons/logoKairosV1.svg"
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LightInvestorRegistration = () => {
    const {
        handleSubmit,
        defaultValues,
        validationSchema,
        fields,
        isLoading,
        loaderText,
        showPassword,
        showConfirmPassword,
        togglePasswordVisibility,
        toggleConfirmPasswordVisibility,
    } = useLightInvestorReg()

    return (
        <>
            <PageLoader isLoading={isLoading} loaderText={loaderText} />
            <Box
                sx={{
                    px: 5,
                    pt: 2,
                    pb: 4,
                    backgroundColor: "white",
                    borderRadius: 3
                }}
            >
                <Header />
                <Formik
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <Stack mb={3} gap={3}>
                            <TextFieldWrapper
                                type="text"
                                name="firstName"
                                placeholder="Enter first name"
                                size="normal"
                                label="First Name"
                                required={true}
                            />
                            <TextFieldWrapper
                                type="text"
                                size="normal"
                                name="lastName"
                                placeholder="Enter last name"
                                label="Last Name"
                                required={true}
                            />
                        </Stack>
                        {fields.map(({ name, type, label, placeholder }) => (
                            <Box mb={3} key={name} sx={{ overflow: "visible" }}>
                                {name === "password" || name === "confirmPassword" ? (
                                    <TextFieldWrapper
                                        size="normal"
                                        name={name}
                                        type={name === "password" ? (showPassword ? "text" : "password") : (showConfirmPassword ? "text" : "password")}
                                        placeholder={placeholder}
                                        label={label}
                                        required
                                        endAdornment={
                                            <IconButton
                                                onClick={name === "password" ? togglePasswordVisibility : toggleConfirmPasswordVisibility}
                                                edge="end"
                                            >
                                                {name === "password" ? (showPassword ? <VisibilityOff /> : <Visibility />) : (showConfirmPassword ? <VisibilityOff /> : <Visibility />)}
                                            </IconButton>
                                        }
                                    />

                                ) : (
                                    <TextFieldWrapper
                                        size="normal"
                                        name={name}
                                        type={type}
                                        placeholder={placeholder}
                                        label={label}
                                        required={true}
                                    />
                                )}
                            </Box>
                        ))}
                        <LoadingButton
                            type="submit"
                            size="large"
                            variant="contained"
                            color="brand"
                            fullWidth
                        >
                            Register
                        </LoadingButton>
                    </Form>
                </Formik>
            </Box>
        </>
    )
}

const Header = () => {
    return (
        <Stack direction="column" alignItems="center">
            <img
                style={{
                    width: "48px",
                    height: "48px",
                    objectFit: "cover"
                }}
                src={logo}
                alt="logoIcon"
            />
            <Typography variant="h2" fontWeight={500}>
                Register on Kairos
            </Typography>
            <Typography variant="body1" mb={4}>
                Join the Kairos Network now.
            </Typography>
        </Stack>
    )
}
export default LightInvestorRegistration
