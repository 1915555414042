import { LoadingButton } from "@mui/lab"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    Box
} from "@mui/material"

const RejectModal = ({
    open,
    handleDismiss,
    handleChange,
    value,
    handleConfirm,
    isLoading
}) => {
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ fontSize: "22px" }}>
                Reject Approval Request
            </DialogTitle>
            <DialogContent>
                <Box mt={1}>
                    <form onSubmit={handleConfirm}>
                        <TextField
                            multiline
                            label="Reason for rejection"
                            fullWidth
                            required
                            rows={3}
                            placeholder="Reason for rejection"
                            value={value}
                            onChange={handleChange}
                            sx={{ mb: 3 }}
                        />
                        <DialogActions>
                            <LoadingButton
                                disabled={isLoading}
                                onClick={handleDismiss}
                                color="brand"
                                sx={{ borderRadius: 99 }}
                            >
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                color="brand"
                                variant="contained"
                                sx={{ borderRadius: 99 }}
                            >
                                Confirm
                            </LoadingButton>
                        </DialogActions>
                    </form>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
export default RejectModal
