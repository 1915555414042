import { useNavigate, useSearchParams } from "react-router-dom"
import { verifySelfOnboardLink } from "services/prospectsServices"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { storeVerifiedData } from "redux/slices/selfOnboardSlice"

export const useLinkVerification = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { get, isLoading } = useSelfOnboardApi()
    const { token: savedToken } = useSelector((state) => state.selfOnboard)
    const [searchParams] = useSearchParams()
    const tokenFromUrl = searchParams?.get("token")

    useEffect(() => {
        if (!savedToken) {
            const _verifyToken = async () => {
                if (!tokenFromUrl) {
                    navigate("/invalid-onboard-link")
                    return
                }

                const resp = await get({
                    apiCaller: verifySelfOnboardLink,
                    params: { token: tokenFromUrl }
                })

                const [data, status] = resp

                if (status !== 200) {
                    navigate("/invalid-onboard-link")
                    return
                }

                dispatch(storeVerifiedData({ ...data, token: tokenFromUrl }))
            }
            _verifyToken()
        }
    }, [get, navigate, dispatch, savedToken, tokenFromUrl])

    return {
        isLoading
    }
}
