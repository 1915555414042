import { LoadingButton } from "@mui/lab"
import { Button, Box, TextField, Typography } from "@mui/material"
import { useSnackbar } from "components/common/snackbar/SnackbarProvider"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { maskEmail, navigateToDashboard } from "redux/slices/authHelper"
import { setAuth } from "redux/slices/authSlice"
import { verifyMFACode } from "services"

const MfaAuthentication = () => {
    const { state } = useLocation()
    const [authCode, setAuthCode] = useState(null)
    const { onError, onSuccess } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!state?.mfaSession || !state?.email) {
            navigate("/login")
        }
    }, [navigate, state])

    const handleSubmit = async () => {
        setLoading(true)
        const resp = await verifyMFACode({
            email: state.email,
            mfaSession: state.mfaSession,
            mfaCode: authCode
        })
        setLoading(false)

        if (resp?.data?.status) {
            setAuthCode(null)
            const userInfo = resp.data.body
            dispatch(setAuth(userInfo))
            onSuccess("You've successfully logged in!")
            navigateToDashboard(userInfo)
        } else {
            onError(resp?.data?.errorMessage || "Failed to verify mfa code")
        }
    }

    return (
        <Box sx={{ maxWidth: 450, p: 4, backgroundColor: "white", borderRadius: "9px" }}>
            <Typography variant="h2" fontWeight={500} mb={1}>
                Multi-factor authentication
            </Typography>
            <Typography variant="body1" mb={4}>
                For added security, you'll need to verify your identity. We have
                sent a verification code to{" "}
                <span
                    style={{
                        fontWeight: 500,
                        letterSpacing: "1px",
                        fontSize: "18px"
                    }}
                >
                    {maskEmail(state?.email)}.
                </span>
            </Typography>
            <TextField
                autoFocus
                color="brand"
                size="normal"
                fullWidth
                name="mfaCode"
                placeholder="Enter verification code"
                label="Verification code"
                required
                sx={{ mb: 3 }}
                onChange={(ev) => setAuthCode(ev.target.value)}
            />
            <LoadingButton
                disabled={loading}
                loading={loading}
                onClick={handleSubmit}
                size="large"
                variant="contained"
                color="brand"
                fullWidth
            >
                Verify code
            </LoadingButton>
            {false && (
                <Button size="large" fullWidth color="brand" sx={{}}>
                    Resend code
                </Button>
            )}
        </Box>
    )
}

export default MfaAuthentication
