import { CONSTANT_ID } from "constant/ConstantsId"
import PropTypes from "prop-types"
import { jwtDecode } from "jwt-decode"
import * as React from "react"
import Box from "@mui/material/Box"
import CardsDisplay from "../../common/CatalogCard/CatalogCard"
import CardsDisplayList from "../../common/CatalogCard/CatalogCardList"
import BordeauxLiveData from "../ThirdPartySections/BordeauxLiveData"
import { useSelector } from "react-redux"

function TabPanel(props) {
    const {
        children,
        value,
        index,
        listitems,
        description,
        isbordeauxenabled,
        ...other
    } = props
    const user = JSON.parse(localStorage.getItem("user"))
    const decodedToken = jwtDecode(user?.idToken)
    const [datalist, setDatalist] = React.useState(null)
    const [loadingInt, setLoadingInt] = React.useState(true)
    const userDataViewTabPanel = useSelector(
        (state) => state.productService.users[btoa(decodedToken?.email)]
    )
    const [readValueForDataFulls, setReadValueForDataFulls] = React.useState(
        () => {
            // Depending on Value selected, data binding will assign
            let dataFulls
            if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
                dataFulls = userDataViewTabPanel?.financialProducts?.dataFull
            } else if (
                props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE
            ) {
                dataFulls = userDataViewTabPanel?.financialServices?.dataFull
            } else if (
                props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT
            ) {
                dataFulls = userDataViewTabPanel?.lifestyleProducts?.dataFull
            } else {
                dataFulls = userDataViewTabPanel?.lifestyleServices?.dataFull
            }
            return dataFulls ? Object.values(dataFulls) : []
        }
    )
    // eslint-disable-next-line
    const [readValueForDataFullsListItem, setReadValueForDataFullsListItem] =
        React.useState(() => {
            let assetClassesListT
            // Depending on Value selected, data binding will assign
            if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
                assetClassesListT =
                    userDataViewTabPanel?.financialProducts?.assetClassesList
            } else if (
                props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE
            ) {
                assetClassesListT =
                    userDataViewTabPanel?.financialServices?.assetClassesList
            } else if (
                props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT
            ) {
                assetClassesListT =
                    userDataViewTabPanel?.lifestyleProducts?.assetClassesList
            } else {
                assetClassesListT =
                    userDataViewTabPanel?.lifestyleServices?.assetClassesList
            }
            return assetClassesListT ? Object.values(assetClassesListT) : [] //Object.values(assetClassesList || {});
        })

    React.useEffect(() => {
        const fetchData = async () => {
            // Get the Particular category
            const data = await getParticularPrimaryData(value)
            setDatalist(data)
        }
        if (value !== 0) {
            setLoadingInt(true)
            setTimeout(() => {
                fetchData()
            }, 1000)
        }
        // eslint-disable-next-line
    }, [value, props?.alignment])

    //Methods to set the state value, depends on its types
    const setReadValueForDataFullsListItemCustom = async () => {
        let assetClassesListTemp
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            assetClassesListTemp =
                userDataViewTabPanel?.financialProducts?.assetClassesList
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            assetClassesListTemp =
                userDataViewTabPanel?.financialServices?.assetClassesList
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            assetClassesListTemp =
                userDataViewTabPanel?.lifestyleProducts?.assetClassesList
        } else {
            assetClassesListTemp =
                userDataViewTabPanel?.lifestyleServices?.assetClassesList
        }
        setReadValueForDataFullsListItem(
            assetClassesListTemp ? Object.values(assetClassesListTemp) : []
        )
    }
    //Methods to set the state value, depends on its types
    const setReadValueForDataFullsListItemCustomDataList = () => {
        let dataFulls
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            dataFulls = userDataViewTabPanel?.financialProducts?.dataFull
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            dataFulls = userDataViewTabPanel?.financialServices?.dataFull
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            dataFulls = userDataViewTabPanel?.lifestyleProducts?.dataFull
        } else {
            dataFulls = userDataViewTabPanel?.lifestyleServices?.dataFull
        }
        setReadValueForDataFulls(dataFulls ? Object.values(dataFulls) : [])
    }
    // the useeffect triggers when value of type change
    React.useEffect(() => {
        setReadValueForDataFullsListItemCustomDataList()
        setReadValueForDataFullsListItemCustom()
        // eslint-disable-next-line
    }, [props?.valueofProductID, userDataViewTabPanel])

    function generateRandomInteger(max) {
        return Math.floor(Math.random() * max) + 1
    }

    //Methods to find the ndivdual category
    async function getParticularPrimaryData(position) {
        let dataFullsTemp
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            dataFullsTemp = Object.values(
                userDataViewTabPanel?.financialProducts?.dataFull
            )
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            dataFullsTemp = Object.values(
                userDataViewTabPanel?.financialServices?.dataFull
            )
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            dataFullsTemp = Object.values(
                userDataViewTabPanel?.lifestyleProducts?.dataFull
            )
        } else {
            dataFullsTemp = Object.values(
                userDataViewTabPanel?.lifestyleServices?.dataFull
            )
        }
        //     console.log("getParticularPrimaryData --------> 1",dataFullsTemp)
        //     let assetClassesListT;
        // // Depending on Value selected, data binding will assign
        // if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
        //     assetClassesListT = userDataViewTabPanel?.financialProducts?.assetClassesList;
        // } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
        //     assetClassesListT = userDataViewTabPanel?.financialServices?.assetClassesList;
        // } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
        //     assetClassesListT = userDataViewTabPanel?.lifestyleProducts?.assetClassesList;
        // } else {
        //     assetClassesListT = userDataViewTabPanel?.lifestyleServices?.assetClassesList;
        // }
        let filterTypes = readValueForDataFullsListItem[position] // Object.values(assetClassesListT)[position];
        let dataresult = dataFullsTemp?.filter(function (e) {
            return e.category === filterTypes?.description
        })

        if (dataresult) {
            setLoadingInt(false)
            return dataresult[0]
        } else {
            setLoadingInt(false)
            return {
                category: filterTypes?.description,
                productServiceCatalogueResponseDto: []
            }
        }
    }

    return (
        <div
            role="tabpanel"
            {...other}
            hidden={value !== index}
            id={`vertical-tabpaneld-${index}`}
            aria-labelledby={`vertical-tabd-${index}`}
        >
            {/** Generate the cards for the All asset classes */}
            {value === index && value < 1 && (
                <>
                    {readValueForDataFulls &&
                        readValueForDataFulls?.length > 0 &&
                        readValueForDataFulls?.map(function (item, i) {
                            return (
                                <Box key={"box" + i}>
                                    {item?.productServiceCatalogueResponseDto
                                        ?.length > 0 && (
                                        <CardsDisplay
                                            url={props?.url}
                                            datalist={
                                                item?.productServiceCatalogueResponseDto
                                            }
                                            header={item?.category}
                                            icon={item?.categoryImage}
                                            colorimage={generateRandomInteger(
                                                9
                                            )}
                                            loading={false}
                                            portfoliochanges={
                                                props?.portfoliochanges
                                            }
                                        />
                                    )}
                                </Box>
                            )
                        })}
                </>
            )}

            {/** Generate the cards for the selected asset class */}
            {value === index && value >= 1 && children !== "BORDEAUX" && (
                <Box>
                    <CardsDisplayList
                        url={props?.url}
                        datalist={datalist || {}}
                        loading={datalist && !loadingInt ? false : true}
                        portfoliochanges={props?.portfoliochanges}
                    />
                </Box>
            )}

            {/** Generate the cards for Bordeaux Index */}
            {value === index &&
                children === "BORDEAUX" &&
                isbordeauxenabled && (
                    <>
                        <BordeauxLiveData />
                    </>
                )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

export default TabPanel
