import React from "react"
import { Pagination, Box, Typography } from "@mui/material"
import { TABLE_ROWS_PER_PAGE } from "constant/globalConstants"

const PaginationComponent = ({
    handlePageChange,
    page,
    totalCount,
    totalRecordsCount,
    size = "large"
}) => {
    const currentPageLowerRange = (page - 1) * TABLE_ROWS_PER_PAGE
    const currentPageUpperRange = Math.min(
        page * TABLE_ROWS_PER_PAGE,
        totalRecordsCount
    )

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: {
                    xs: "flex-end",
                    sm: "flex-end",
                    md: "space-between"
                },
                alignItems: "center",
                pt: 2,
                px: 2
            }}
        >
            {totalCount > 0 ? (
                totalRecordsCount && (
                    <Typography
                        sx={{
                            display: { xs: "none", sm: "none", md: "block" }
                        }}
                    >
                        Showing {currentPageLowerRange} &mdash;{" "}
                        {currentPageUpperRange} of {totalRecordsCount} results
                    </Typography>
                )
            ) : (
                <Typography>No results to show </Typography>
            )}
            <Pagination
                count={totalCount}
                sx={{
                    ml: "auto",
                    mb: 2,
                    "& .MuiPaginationItem-root.Mui-selected ": {
                        backgroundColor: "#643DD6",
                        color: "#fafafa"
                    },
                    "& .MuiPaginationItem-root": {
                        border: "1px solid #d4d4d4"
                    },
                    "& .MuiPaginationItem-ellipsis": { border: "none" }
                }}
                page={page}
                shape="rounded"
                variant="outlined"
                onChange={handlePageChange}
                size={size}
            />
        </Box>
    )
}

export default PaginationComponent
