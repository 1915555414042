import { Box, Typography } from "@mui/material"
import { Outlet } from "react-router-dom"
const RegistrationLayout = () => {
    return (
        <Box
            sx={{
                minHeight: "100dvh",
                display: "flex",
                justifyContent: "center",
                pt: 6,
                pb: 3,
                backgroundImage:
                    "linear-gradient( to bottom, hsl(252, 52%, 5%), hsl(252, 52%, 50%));"
            }}
        >
            <Box sx={{ maxWidth: 550, width: "90%" }}>
                <Typography
                    variant="h1"
                    sx={{
                        fontFamily: "The Seasons",
                        fontWeight: 500,
                        color: "white",
                        fontSize: "56px",
                        mb: 4,
                        textAlign: "center"
                    }}
                >
                    The future of wealth
                </Typography>
                <Outlet />
            </Box>
        </Box>
    )
}
export default RegistrationLayout
