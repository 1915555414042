import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import Layout from "../Layout/Layout"
import { AppLogout } from "../../routes/AppLogout"
import { fetchGeneralDropdowns } from "redux/slices/generalSlice"
import { getLoggedInUserInfo } from "constant/helpersUtility"

const PrivateRoutes = () => {
    const dispatch = useDispatch()
    const { countries } = useSelector((state) => state.general)
    const { user } = useSelector((state) => state.auth)
    const [userLocalObj] = useState(getLoggedInUserInfo)

    const isUserLoggedIn =
        (user && Object.keys(user)?.length > 0) ||
        (userLocalObj && Object.keys(userLocalObj).length > 0)
    const countryExists = countries?.length > 0

    useEffect(() => {
        if (!countryExists) {
            dispatch(fetchGeneralDropdowns())
        }
    }, [isUserLoggedIn, countryExists, dispatch])

    return isUserLoggedIn ? (
        <AppLogout>
            <Layout>
                <Outlet />
            </Layout>
        </AppLogout>
    ) : (
        <Navigate to="/" />
    )
}

export default PrivateRoutes
