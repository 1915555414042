import { Box, InputAdornment, TextField, Typography } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

const TaskSearchBar = ({ handleChange, searchTerm }) => {
    return (
        <Box mb={2}>
            <Typography mb={1} variant="h4" fontWeight={500}>
                Approval Management
            </Typography>
            <TextField
                value={searchTerm}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                sx={{
                    ".MuiInputBase-root": { borderRadius: 99, width: "500px" }
                }}
                color="brand"
                variant="outlined"
                size="small"
                placeholder="Search by keyword in description..."
                onChange={handleChange}
            />
        </Box>
    )
}
export default TaskSearchBar
