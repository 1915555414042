
export const navigateToDashboard = (userInfo) => {
    const isInvestor = userInfo?.userTypeId?.description === "Investor"
    if (isInvestor) {
        window.location.replace(`${window.location.origin}/dealroom`)
    } else {
        window.location.replace(`${window.location.origin}/dashboard`)
    }
}

/*
 * londonsmithrm1@yopmail.com (original)
 * lo************@y******.com (masked)
*/
export const maskEmail = (email) => {
    if (!email) return ""
    const [name, domainPart] = email.split("@")

    // ** regex to match all chars
    const regex = /./gi

    // generate * for name part, except for 2 initial chars
    const maskedName = name.substring(2).replaceAll(regex, "*")

    // ** Take out the start index of main domain: eg: abc@info.com,
    const startIndexOfMainDomain = domainPart.lastIndexOf(".")

    // ** generate * except for first char till start of domain
    const maskedDomainPart = domainPart
        .substring(1, startIndexOfMainDomain)
        .replaceAll(regex, "*")

    const maskedEmai =
        name.substring(0, 2) +
        maskedName +
        "@" +
        domainPart.charAt(0) +
        maskedDomainPart +
        domainPart.substring(startIndexOfMainDomain)
    return maskedEmai
}
