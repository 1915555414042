import { Box, Button, Stack, Typography } from "@mui/material"
import SelfOnboardPage from "../SelfOnboard"
import { KeyboardBackspace } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect, useRef } from "react"

const VerifyLightInvestor = () => {
    const { activeStep } = useSelector((state) => state.selfOnboard)
    const containerRef = useRef(null)

    useEffect(() => {
        const element = containerRef?.current?.parentElement
        element.scrollIntoView()
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }, [activeStep])

    return (
        <Box
            sx={{
                minHeight: "100dvh",
                mx: "auto",
                maxWidth: "1140px",
                width: "90%",
                my: "auto"
            }}
            ref={containerRef}
        >
            <Header />
            <div>
                <SelfOnboardPage />
            </div>
        </Box>
    )
}

const Header = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                px: {
                    xs: 3,
                    md: 6
                },
                py: 2.5,
                bgcolor: "#fff",
                mb: 4,
                borderRadius: 3,
                borderWidth: 1,
                borderColor: "#EBEBEB",
                borderStyle: "solid",
                boxShadow: "0px 2px 10px 0px #CDCDCD1A"
            }}
        >
            <Stack flexDirection={{ xs: "column", md: "row" }}>
                <Button
                    startIcon={<KeyboardBackspace />}
                    color="brand"
                    sx={{ alignSelf: "start", borderRadius: 99 }}
                    onClick={() => {
                        navigate("/dealroom")
                    }}
                >
                    Go back
                </Button>
                <Box
                    sx={{
                        mx: "auto"
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontFamily: "The Seasons",
                            color: "brand.dark",
                            fontWeight: 500,
                            mb: 0.5,
                            fontSize: {
                                xs: "24px",
                                md: "28px"
                            }
                        }}
                    >
                        Join the Kairos Network now.
                    </Typography>
                    <Typography varaint="body1">
                        Complete Your Verification to Unlock Investment
                        Opportunities
                    </Typography>
                </Box>
                <Box></Box>
            </Stack>
        </Box>
    )
}
export default VerifyLightInvestor
