import { createSlice } from "@reduxjs/toolkit"

const setIsReadyForApproval = (state, data) => {
    const {
        dealIssuer,
        dealName,
        dealSummary,
        dealType,
        financingInstrument,
        industry,
        dealCurrency,
        targetRaiseAmount,
        minimumTicket,
        maximumTicket,
        country,
        allocation
    } = data
    if (
        dealIssuer &&
        dealName &&
        dealSummary &&
        dealType &&
        financingInstrument &&
        industry &&
        dealCurrency &&
        targetRaiseAmount &&
        minimumTicket &&
        maximumTicket &&
        country &&
        allocation
    ) {
        state.isDealReadyForApproval = true
    } else {
        state.isDealReadyForApproval = false
    }
}

const dealRoomSlice = createSlice({
    name: "dealRoom",
    initialState: {
        dealsData: [],
        selectedDeal: {},
        addMetricModalOpen: false,
        addInvestorModalOpen: false,
        addTeamModalOpen: false,
        addDocumentModalOpen: false,
        editDealFormSubmitTriggered: false,
        editDealValidationErrors: false,
        isDealReadyForApproval: false,
        invitedInvestors: [],
        refreshInvestorList: false,
        refreshSelectedDeal: false
    },
    reducers: {
        setDealsData: (state, action) => {
            state.dealsData = action.payload
        },
        setSelectedDeal: (state, action) => {
            state.selectedDeal = action.payload
            setIsReadyForApproval(state, action.payload)
        },
        setAddMetricModalOpen: (state, action) => {
            state.addMetricModalOpen = action.payload
        },
        setAddInvestorModalOpen: (state, action) => {
            state.addInvestorModalOpen = action.payload
        },
        setAddTeamModalOpen: (state, action) => {
            state.addTeamModalOpen = action.payload
        },
        setAddDocumentModalOpen: (state, action) => {
            state.addDocumentModalOpen = action.payload
        },
        editDealTriggerFormSubmit: (state) => {
            state.editDealFormSubmitTriggered = true
        },
        editDealResetFormSubmit: (state) => {
            state.editDealFormSubmitTriggered = false
        },
        setEditDealValidationErrors: (state, action) => {
            // when error obj is null we know form is valid and ready for approval
            if (action.payload === null) {
                state.isDealReadyForApproval = true
            } else {
                state.isDealReadyForApproval = false
            }
            state.editDealValidationErrors = action.payload
        },
        setInvitedInvestors: (state, action) => {
            console.log(action, "ACCC")
            state.invitedInvestors = action.payload
        },
        invalidateInvestorList: (state) => {
            state.refreshInvestorList = !state.refreshInvestorList
        },
        invalidateSelectedDeal: (state) => {
            state.refreshSelectedDeal = !state.refreshSelectedDeal
        }
    }
})
export const {
    setDealsData,
    setSelectedDeal,
    setAddMetricModalOpen,
    setAddInvestorModalOpen,
    setAddTeamModalOpen,
    setAddDocumentModalOpen,
    editDealTriggerFormSubmit,
    editDealResetFormSubmit,
    setEditDealValidationErrors,
    setInvitedInvestors,
    invalidateInvestorList,
    invalidateSelectedDeal
} = dealRoomSlice.actions

export default dealRoomSlice.reducer
