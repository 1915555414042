import { Button } from "@mui/material"
import { AccessTime, OpenInNew } from "@mui/icons-material"
import { useVerificationToken } from "./useVerificationToken"
import { useNavigate } from "react-router-dom"

const VerifyButtonWithLabel = () => {
    const { token, verificationStatus } = useVerificationToken()
    const navigate = useNavigate()

    const handleVerifyClick = () => {
        const url = `/get-verified/?token=${token}`
        navigate(url)
    }

    return (
        <>
            {verificationStatus === "WFA" ? (
                <Button
                    size="large"
                    fullWidth
                    color="warning"
                    endIcon={<AccessTime />}
                >
                    Verificaton pending
                </Button>
            ) : (
                <Button
                    size="large"
                    onClick={handleVerifyClick}
                    fullWidth
                    color="brand"
                    variant="outlined"
                    endIcon={<OpenInNew />}
                >
                    Get Verified
                </Button>
            )}
        </>
    )
}
export default VerifyButtonWithLabel
