import { Grid, Button } from "@mui/material"
import { getLoggedInRole } from "constant/helpersUtility"
import { useTranslation } from "react-i18next"
import { useState } from "react"

const FinancialFilterTabs = ({ selectedButton, handleButtonClick }) => {
    const [loggedInUserRole] = useState(() => getLoggedInRole())
    const { t } = useTranslation()

    const allTabs = [
        {
            value: t("Stocks"),
            englishValue: "Stocks"
        },
        {
            value: t("Internal Data"),
            englishValue: "Internal Data"
        },
        {
            value: t("Portfolio"),
            englishValue: "Portfolio"
        },
        {
            value: t("Agent"),
            englishValue: "Agent"
        }
    ]
    const investorTabs = [
        {
            value: t("Stocks"),
            englishValue: "Stocks"
        },
        {
            value: t("Internal Data"),
            englishValue: "Internal Data"
        },
        {
            value: t("Agent"),
            englishValue: "Agent"
        }
    ]

    const tabs = loggedInUserRole === "INVESTOR" ? investorTabs : allTabs

    return (
        <Grid container spacing={1} px={1}>
            {tabs.map((label, index) => (
                <Grid key={index} item xs={12} lg={6}>
                    <Button
                        key={index}
                        fullWidth={true}
                        color="brand"
                        onClick={() => handleButtonClick(label)}
                        variant={
                            selectedButton === label.value
                                ? "contained"
                                : "outlined"
                        }
                    >
                        {label.value}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )
}
export default FinancialFilterTabs
