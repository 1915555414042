const keysToRemove = [
    "CampaignColumnChooser",
    "CampaignHistoryColumnChooser",
    "ClientColumnChooser",
    "columnsLocalProducts",
    "columnslocal",
    "enquiryColumnChooser",
    "entityId",
    "familyOfficeType",
    "feedbackColumnChooser",
    "logger:level",
    "serviceColumnChooser",
    "user",
    "userColumnChoose41",
    "modals",
    "sectimer",
    "tickersList",
    "userColumnChooser",
    "lastActiveTime"
]

export function clearLocalStorageExceptPersistRoot() {
    keysToRemove.forEach((key) => localStorage.removeItem(key))
}
