import React, { useState, useCallback } from "react"

import { getLoggedInUserInfo } from "constant/helpersUtility"
const withPermissionsHoc = (Component, name) => (props) => {
    const [user] = useState(() => {
        const user = getLoggedInUserInfo() || {}
        return user
    })

    // eslint-disable-next-line
    const permissions = useCallback(
        user?.userRights?.find(
            (p) => p?.module?.replace(/\s/g, "") === name?.replace(/\s/g, "")
        ),
        [user]
    )

    const readPermission = permissions?.readOnly || false
    const writePermission = permissions?.create || false
    const approvePermission = permissions?.approve || false

    if (!(readPermission || writePermission || approvePermission)) {
        return null
    }

    return (
        <Component
            readPermission={readPermission}
            writePermission={writePermission}
            approvePermission={approvePermission}
            {...props}
        />
    )
}

export default withPermissionsHoc
