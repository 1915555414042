import { IconButton } from "@mui/material"
import { AccessTime, OpenInNew } from "@mui/icons-material"
import { useVerificationToken } from "./useVerificationToken"
import { useNavigate } from "react-router-dom"

const VerifyButtonIcon = () => {
    const { token, verificationStatus } = useVerificationToken()
    const navigate = useNavigate()

    const handleVerifyClick = () => {
        const url = `/get-verified/?token=${token}`
        navigate(url)
    }
    return (
        <>
            {verificationStatus === "WFA" ? (
                <IconButton color="warning">
                    <AccessTime />
                </IconButton>
            ) : (
                <IconButton color="brand" onClick={handleVerifyClick}>
                    <OpenInNew />
                </IconButton>
            )}
        </>
    )
}
export default VerifyButtonIcon
