import { Snackbar, Alert } from "@mui/material"

export default function DefaultSnackbar({ isOpen, onClose, message, isError }) {
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={6000}
            onClose={onClose}
            message={message}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert
                variant={isError ? "standard" : "filled"}
                onClose={onClose}
                severity={isError ? "error" : "success"}
                sx={{ width: "100%" }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}
