import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { setInitialState } from "redux/slices/selfOnboardSlice"

const useReduxInitializer = () => {
    const [searchParams] = useSearchParams()
    const token = searchParams.get("token")
    const dispatch = useDispatch()

    useEffect(() => {
        const onboard = JSON.parse(localStorage.getItem(`onboard/${token}`))
        dispatch(setInitialState(onboard))
        localStorage.setItem("current-user-token", token)
    }, [token, dispatch])
}

export default useReduxInitializer
