import React, { useState } from "react"
import { rejectwithReasonsorApproval } from "../../../../services/productManagement" // eslint-disable-next-line
import {
    managApproveorReject,
    approveRejectProductsServices,
    approveUserStatus,
    rejectUserStatus,
    compApproveorReject,
    getAdminDashboardDataWIthSection
} from "../../../../services"
import { approveOrRejectDeal } from "../../../../services/dealRoomService"
import { submitVendorRejection } from "../../../../services/vendormanagement"
import GridLayout from "./gridLayout"
import { getIndividualTaskPaginations } from "../../../../services/DashboardAPI"
import {
    updateAPI1,
    updateAPI2,
    updateAPI3,
    updateAPI4
} from "../../../../redux/slices/dashboardSlice"
import { useDispatch, useSelector } from "react-redux"
import { jwtDecode } from "jwt-decode"
import RejectionModal from "./RejectModal"
import { useDisclosure } from "hooks/useDisclosure"
import { useSnackbar } from "components/common/snackbar/SnackbarProvider"
import { PageLoader } from "components/common/Ui/Form"

const rowsPerPage = 10
function AdminDashboard() {
    const user = JSON.parse(localStorage.getItem("user"))
    const decodedToken = jwtDecode(user?.idToken)
    const [initialAPIResponnse, setInitialAPIResponnse] = React.useState({})
    const [initialAPIResponnseSecond, setInitialAPIResponnseSecond] =
        React.useState({})
    const [page, setPage] = React.useState(1) // eslint-disable-next-line
    const [isLoading, setLoading] = React.useState(false)
    const [reason, setReason] = React.useState("")
    const [rejId, setRejId] = React.useState("")
    const [search, setSearch] = React.useState("")
    const [rejectpath, setRejectpath] = React.useState("")
    const [status, setStatus] = React.useState("")
    const [mountingFirst, setMountingFirst] = React.useState(true)
    const [isDataFetching, setIsDataFetching] = useState(false)
    const [isRejecting, setIsRejecting] = useState(false)
    const { onSuccess, onError } = useSnackbar()
    const dispatch = useDispatch()
    const {
        open: openRejectModal,
        close: closeRejectModal,
        isOpen: isRejectModalOpen
    } = useDisclosure()
    //depending on user mail id -> We use base64 format to encrypt the user mail id., the data fetch from redux presist.
    const dashboardContent = useSelector(
        (state) => state.dashboard[btoa(decodedToken?.email)]
    )

    React.useEffect(() => {
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: page - 1,
            pageSize: rowsPerPage,
            search: search
        }
        setMountingFirst(false)
        const timeOut = setTimeout(() => taskAPICALL(req), 1000)
        return () => clearTimeout(timeOut)

        // eslint-disable-next-line
    }, [search])

    React.useEffect(() => {
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: page - 1,
            pageSize: rowsPerPage,
            search: ""
        }
        listapi(req)
        // eslint-disable-next-line
    }, [])

    const handleClickReject = (id, path, message) => {
        openRejectModal()
        setReason("")
        setRejId(id)
        setRejectpath(path)
        setStatus(message)
    }

    const handleApprove = (e, id, path, profilestatus) => {
        setLoading(true)
        e.preventDefault()
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: page - 1,
            pageSize: rowsPerPage,
            search: ""
        }
        const obj = {
            status: "Approve",
            loginUserId: user?.userId
        }

        if (path.includes("/userManagement")) {
            approveUserStatus({ ...obj, userId: Number(id) })
                .then((response) => {
                    if (response.data.status) {
                        listapi(req)
                        onSuccess(response.data.statusMessage)
                    } else {
                        onError(response.data.statusMessage)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("errors inside catch", error)
                })
        } else if (
            path.includes("/lifestyleproducts") ||
            path.includes("/financialproducts")
        ) {
            const serviceobj = {
                productServiceId: Number(id),
                status: "Approve",
                userId: user.userId
            }
            rejectwithReasonsorApproval(
                serviceobj,
                path.includes("/lifestyleproducts")
                    ? "lifestyleproducts"
                    : "financialproducts"
            ).then((resultOfAPI) => {
                if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                    listapi(req)
                    onSuccess(resultOfAPI.data.statusMessage)
                } else {
                    onError(resultOfAPI.data.errorMessage)
                }
                setLoading(false)
            })
        } else if (
            path.includes("/financialservices") ||
            path.includes("/lifestyleservices")
        ) {
            const serviceobj = {
                productServiceId: Number(id),
                status: "Approve",
                userId: user.userId
            }
            approveRejectProductsServices(
                serviceobj,
                path.includes("/financialservices")
                    ? "financialservices"
                    : "lifestyleservices"
            )
                .then((response) => {
                    if (response.data.status) {
                        listapi(req)
                        onSuccess(response.data.statusMessage)
                    } else {
                        onError(response.data.statusMessage)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.log("errors inside catch", error)
                })
        } else if (path.includes("/vendorManagement")) {
            const passingValue = {
                userId: user.userId,
                vendorId: Number(id),

                status: "Approve",
                reason: ""
            }
            submitVendorRejection(passingValue).then((resultOfAPI) => {
                if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                    listapi(req)
                    onSuccess(resultOfAPI.data.statusMessage)
                } else {
                    onError(resultOfAPI.data.errorMessage)
                }
                setLoading(false)
            })
        } else if (
            path.includes("/clientprofile") ||
            path.includes("/prospectprofile")
        ) {
            if (
                profilestatus === "compliance-approve-or-reject" ||
                profilestatus === "Pending for Compliance Approval"
            ) {
                let profileobj = {
                    userId: user.userId,
                    prospectId: Number(id),
                    status: "Approve",
                    reason: ""
                }
                compApproveorReject(profileobj).then((approveresponse) => {
                    if (approveresponse?.data?.status) {
                        listapi(req)
                        onSuccess(approveresponse?.data?.statusMessage)
                    } else {
                        onError(approveresponse?.data?.errorMessage)
                    }
                    setLoading(false)
                })
            }
            if (profilestatus === "management-approve-or-reject") {
                let profileobj = {
                    userId: user.userId,
                    prospectId: Number(id),
                    status: "Approve"
                }
                managApproveorReject(profileobj).then((approveresponse) => {
                    if (approveresponse?.data?.status) {
                        listapi(req)
                        onSuccess(approveresponse?.data?.statusMessage)
                    } else {
                        onError(approveresponse?.data?.errorMessage)
                    }
                    setLoading(false)
                })
            }
        } else if (path.includes("/dealroom")) {
            const parts = path.split("/")
            const uuid = parts[parts.length - 1]
            const payload = {
                status: "A",
                remarks:
                    "This investment opportunity is approved by the Administrator"
            }
            approveOrRejectDeal(uuid, payload).then((response) => {
                if (response.data.status) {
                    listapi(req)
                    onSuccess(response.data.statusMessage)
                } else {
                    onError(response.data.errorMessage)
                }
                setLoading(false)
            })
        }
    }

    const handlePageChange = async (e, value) => {
        setPage(value)
        const listObj = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: rowsPerPage,
            search: search ? search : ""
        }
        taskAPICALL(listObj)
    }

    const handleReasonChange = (e) => {
        setReason(e.target.value)
    }

    const handleReasonSubmission = (profilestatus) => {
        setIsRejecting(true)
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: page - 1,
            pageSize: rowsPerPage,
            search: ""
        }
        if (rejectpath.includes("/userManagement")) {
            const obj = {
                userId: Number(rejId),
                status: "Rejected",
                reason: reason,
                loginUserId: user?.userId
            }
            rejectUserStatus(obj)
                .then((response) => {
                    if (response.data.status) {
                        onSuccess(response.data.statusMessage)
                        taskAPICALL(req)
                    } else {
                        onError(response.data.statusMessage)
                    }
                })
                .catch((error) => {
                    onError("errors inside catch of rejection", error)
                })
                .finally(() => {
                    setIsRejecting(false)
                    closeRejectModal()
                })
        } else if (
            rejectpath.includes("/lifestyleproducts") ||
            rejectpath.includes("/financialproducts")
        ) {
            const passingValue = {
                userId: user.userId,
                productServiceId: Number(rejId),
                status: "Rejected",
                reason: reason
            }
            if (reason.trim()?.length > 0) {
                rejectwithReasonsorApproval(
                    passingValue,
                    rejectpath.includes("/lifestyleproducts")
                        ? "lifestyleproducts"
                        : "financialproducts"
                )
                    .then((resultOfAPI) => {
                        if (
                            resultOfAPI.data.statusMessage &&
                            resultOfAPI.data.status
                        ) {
                            onSuccess(resultOfAPI.data.statusMessage)
                            taskAPICALL(req)
                        } else {
                            onError(resultOfAPI.data.errorMessage)
                        }
                    })
                    .finally(() => {
                        setIsRejecting(false)
                        closeRejectModal()
                    })
            }
        } else if (
            rejectpath.includes("/financialservices") ||
            rejectpath.includes("/lifestyleservices")
        ) {
            const obj = {
                userId: user?.userId,
                status: "Rejected",
                productServiceId: Number(rejId),
                reason: reason
            }
            approveRejectProductsServices(
                obj,
                rejectpath.includes("/financialservices")
                    ? "financialservices"
                    : "lifestyleservices"
            )
                .then((response) => {
                    if (response.data.status) {
                        onSuccess(response.data.statusMessage)
                    } else {
                        onError(response.data.statusMessage)
                    }
                })
                .catch((error) => {
                    onError("errors inside catch of rejection", error)
                })
                .finally(() => {
                    setIsRejecting(false)
                    closeRejectModal()
                })
        } else if (rejectpath.includes("/vendorManagement")) {
            const passingValue = {
                userId: user.userId,
                vendorId: Number(rejId),
                status: "Rejected",
                reason: reason
            }
            if (reason.trim()?.length > 0) {
                submitVendorRejection(passingValue)
                    .then((resultOfAPI) => {
                        if (
                            resultOfAPI.data.statusMessage &&
                            resultOfAPI.data.status
                        ) {
                            onSuccess(resultOfAPI.data.statusMessage)
                            taskAPICALL(req)
                        } else {
                            onError(resultOfAPI.data.errorMessage)
                        }
                    })
                    .finally(() => {
                        setIsRejecting(false)
                        closeRejectModal()
                    })
            }
        } else if (
            rejectpath.includes("/clientprofile") ||
            rejectpath.includes("/prospectprofile")
        ) {
            if (
                profilestatus === "compliance-approve-or-reject" ||
                profilestatus === "Pending for Compliance Approval"
            ) {
                let obj = {
                    userId: user.userId,
                    prospectId: Number(rejId),
                    status: "Rejected",
                    reason: reason
                }
                compApproveorReject(obj)
                    .then((approveresponse) => {
                        if (approveresponse?.data?.status) {
                            taskAPICALL(req)
                            onSuccess(approveresponse?.data?.statusMessage)
                        } else {
                            onError(approveresponse?.data?.errorMessage)
                        }
                    })
                    .finally(() => {
                        setIsRejecting(false)
                        closeRejectModal()
                    })
            }
            if (
                profilestatus === "management-approve-or-reject" ||
                profilestatus === "Pending for Management Approval"
            ) {
                let obj = {
                    userId: user.userId,
                    prospectId: Number(rejId),
                    status: "Rejected",
                    reason: reason
                }
                managApproveorReject(obj)
                    .then((approveresponse) => {
                        if (approveresponse?.data?.status) {
                            taskAPICALL(req)
                            onSuccess(approveresponse?.data?.statusMessage)
                        } else {
                            onError(approveresponse?.data?.errorMessage)
                        }
                    })
                    .finally(() => {
                        setIsRejecting(false)
                        closeRejectModal()
                    })
            }
        } else if (rejectpath.includes("/dealroom")) {
            const parts = rejectpath.split("/")
            const uuid = parts[parts.length - 1]
            const payload = {
                status: "R",
                remarks: reason
            }
            approveOrRejectDeal(uuid, payload)
                .then((resp) => {
                    if (resp?.data?.status) {
                        taskAPICALL(req)
                        onSuccess(resp?.data?.statusMessage)
                    } else {
                        onError(resp?.data?.errorMessage)
                    }
                })
                .finally(() => {
                    setIsRejecting(false)
                    closeRejectModal()
                })
        }
    }

    const handlefilter = (e) => {
        setPage(1)
        const keyword = e.target.value
        const trimmedKeyword = keyword.trimStart()
        setSearch(trimmedKeyword)
    }

    // API Call to get the list of tasks
    const taskAPICALL = async (req) => {
        await getIndividualTaskPaginations(req).then((res) => {
            if (res?.data?.status) {
                if (!mountingFirst) {
                    dispatch(
                        updateAPI3({
                            user: btoa(decodedToken?.email),
                            data: {
                                ...dashboardContent?.api3,
                                totalCount: res?.data?.body?.task?.totalPages,
                                list: res?.data?.body?.task?.notifications
                            }
                        })
                    )
                }
            }
        })
    }

    const listapi = async (req) => {
        if (
            !dashboardContent?.api1 &&
            !dashboardContent?.api2 &&
            !dashboardContent?.api3 &&
            !dashboardContent?.api4
        ) {
            setLoading(true)
        }
        setIsDataFetching(true)

        for (let [index, promise] of [
            { ...req, sequence: 1 },
            { ...req, sequence: 2 },
            { ...req, sequence: 3 },
            { ...req, sequence: 4 }
        ].entries()) {
            try {
                const res = await getAdminDashboardDataWIthSection(promise)
                if (index === 0) {
                    if (res?.data?.status) {
                        // All available fields to filter:  "Invested Wealth" "Total AUM" "Total Client Net Worth"  "No. of Clients" "No. of RMs" "Total Partners/Vendor"
                        const {
                            label: completeLevel,
                            ttmAggregateAumBarChart: {
                                data: origData,
                                label: origLabels
                            }
                        } = res.data.body

                        const data = []
                        completeLevel.forEach((label, index) => {
                            const capitalizedLabel =
                                label.charAt(0) + label.slice(1).toLowerCase()
                            const position =
                                origLabels.indexOf(capitalizedLabel)
                            data[index] =
                                position === -1 ? 0 : origData[position]
                        })

                        const cardsData = res?.data?.body?.cardData.filter(
                            (card) =>
                                [
                                    "Total AUM",
                                    "Invested Wealth",
                                    "No. of Clients"
                                ].includes(card.header)
                        )

                        dispatch(
                            updateAPI1({
                                user: btoa(decodedToken?.email),
                                data: {
                                    cardList: cardsData,
                                    ttmInvestedWealthBarChart:
                                        res?.data?.body
                                            ?.ttmInvestedWealthBarChart,
                                    ttmAggregateAumBarChart: {
                                        label: completeLevel,
                                        data
                                    }
                                }
                            })
                        )
                    }
                    setLoading(false)
                }
                if (index === 1) {
                    if (res?.data?.status) {
                        setInitialAPIResponnse({
                            ...initialAPIResponnse,
                            revenueTotalPages:
                                res?.data?.body?.revenueTotalPages,
                            aumTotalPages: res?.data?.body?.aumTotalPages
                        })
                        await dispatch(
                            updateAPI2({
                                user: btoa(decodedToken?.email),
                                data: {
                                    aggregateSectorAllocation:
                                        res?.data?.body?.sectorAssetAllocation,
                                    aggregateAssetAllocation:
                                        res?.data?.body
                                            ?.aggregateAssetAllocation,
                                    geographicalAssetAllocation:
                                        res?.data?.body
                                            ?.geographicalAssetAllocation,
                                    revenueLeaderboardLocalState:
                                        res?.data?.body?.revenueLeaderboard,
                                    aumLeaderboardLocalState:
                                        res?.data?.body?.aumLeaderboard,
                                    initialAPIResponnse: {
                                        ...initialAPIResponnse,
                                        revenueTotalPages:
                                            res?.data?.body?.revenueTotalPages,
                                        aumTotalPages:
                                            res?.data?.body?.aumTotalPages
                                    }
                                }
                            })
                        )
                    }
                    setLoading(false)
                }
                if (index === 2) {
                    if (res?.data?.status) {
                        setInitialAPIResponnseSecond({
                            ...initialAPIResponnseSecond,
                            prospectTopNetworthTotalPages:
                                res?.data?.body?.prospectTopNetworthTotalPages,
                            clientTopNetworthTotalPages:
                                res?.data?.body?.clientTopNetworthTotalPages
                        })
                        await dispatch(
                            updateAPI3({
                                user: btoa(decodedToken?.email),
                                data: {
                                    initialAPIResponnseSecond: {
                                        ...initialAPIResponnseSecond,
                                        prospectTopNetworthTotalPages:
                                            res?.data?.body
                                                ?.prospectTopNetworthTotalPages,
                                        clientTopNetworthTotalPages:
                                            res?.data?.body
                                                ?.clientTopNetworthTotalPages
                                    },
                                    totalCount:
                                        res?.data?.body?.task?.totalPages,
                                    list: res?.data?.body?.task?.notifications,
                                    prospectTopNetWorthLocalState:
                                        res?.data?.body?.prospectTopNetworth,
                                    clientTopNetWorthLocalState:
                                        res?.data?.body?.clientTopNetworth
                                }
                            })
                        )
                    }
                    setLoading(false)
                }
                if (index === 3) {
                    if (res?.data?.status) {
                        dispatch(
                            updateAPI4({
                                user: btoa(decodedToken?.email),
                                data: {
                                    marketDetails:
                                        res?.data?.body?.marketNews?.marketNews
                                }
                            })
                        )
                    }
                    setLoading(false)
                }
            } catch (error) {
                console.error(error.message)
            }
        }
        setIsDataFetching(false)
    }

    const handleConfirmation = (e) => {
        e.preventDefault()
        console.log(reason, "REAS")
        let payload = ""

        if (status === "Pending for Compliance Approval") {
            payload = "compliance-approve-or-reject"
        } else if (status === "Pending for Management Approval") {
            payload = "management-approve-or-reject"
        }
        handleReasonSubmission(payload)
    }

    return (
        <>
            <PageLoader
                isLoading={isLoading}
                loaderText="Finalizing approval, please hold"
            />
            {/** Main layout */}
            <GridLayout
                handleReject={handleClickReject}
                handleApprove={handleApprove}
                isDataFetching={isDataFetching}
                search={search} //search text
                handlefilter={handlefilter} // filter method to change the state
                handlePageChange={handlePageChange} // handle page change for Server siide pagination
                page={page}
            />
            <RejectionModal
                isLoading={isRejecting}
                value={reason}
                handleChange={handleReasonChange}
                handleDismiss={closeRejectModal}
                open={isRejectModalOpen}
                handleConfirm={handleConfirmation}
            />
        </>
    )
}

export default AdminDashboard
