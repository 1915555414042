import { useSnackbar } from "components/common/snackbar/SnackbarProvider"
import { getLoggedInUserInfo } from "constant/helpersUtility"
import { useEffect, useState } from "react"
import { getSelfRegistrationHash } from "services/prospectsServices"

export const useVerificationToken = () => {
    const [user] = useState(getLoggedInUserInfo)
    const [token, setToken] = useState("")
    const [verificationStatus, setVerificationStatus] = useState("UNVERIFIED")
    const rmId = user?.entityId
    const { onError } = useSnackbar()

    useEffect(() => {
        const _fetchToken = async () => {
            const resp = await getSelfRegistrationHash({ rmId })
            if (resp?.data?.status) {
                const hash = resp?.data?.body
                setToken(hash)
                setVerificationStatus("UNVERIFIED")
            } else {
                const status = resp?.data?.body
                if (status === "WFA") {
                    setVerificationStatus("WFA")
                    setToken(null)
                } else {
                    onError("Failed to get self-verification hash")
                }
            }
        }

        _fetchToken()
    }, [rmId, onError])

    return {
        token,
        verificationStatus
    }
}
