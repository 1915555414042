import {
    Box,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material"
import { formatDate } from "components/common/Utils/DateFormat"

import {
    TaskAltOutlined,
    NotInterested,
    VisibilityOutlined
} from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
export default function TableRowWrapper({
    message,
    approvalType,
    entity,
    sender,
    status,
    handleApproveClick,
    handleRejectClick,
    createdAt,
    approvedBy,
    viewPath,
    isKairosAdmin,
    isBtnDisabled
}) {
    const navigate = useNavigate()
    const st = status?.toLowerCase()

    const handleView = () => {
        navigate(viewPath)
    }

    return (
        <TableRow>
            <TableCell>{formatDate(createdAt, "DD/MM/YYYY")}</TableCell>
            {isKairosAdmin && <TableCell>{entity}</TableCell>}
            <TableCell>{approvalType}</TableCell>
            <TableCell
                sx={{
                    display: {
                        xs: "none",
                        lg: "table-cell"
                    }
                }}
            >
                <Tooltip title={message}>
                    <Typography
                        sx={{
                            maxWidth: "65ch",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2
                        }}
                    >
                        {message}
                        <span style={{ color: "#777" }}>
                            {" - "}
                            {sender}
                        </span>
                    </Typography>
                </Tooltip>
            </TableCell>
            {st === "approved" || st === "rejected" ? (
                <TableCell>
                    <Tooltip title={`${status} by ${approvedBy}`}>
                        <Typography
                            sx={{
                                textTransform: "capitalize",
                                display: "inline-block",
                                fontSize: "12px",
                                fontWeight: "500",
                                px: 2,
                                py: 0.5,
                                borderRadius: 99,
                                color:
                                    st === "approved" ? "green.600" : "red.600",
                                bgcolor:
                                    st === "approved" ? "green.50" : "red.50"
                            }}
                        >
                            {st}
                        </Typography>
                    </Tooltip>
                    <Tooltip title="View details">
                        <IconButton
                            onClick={handleView}
                            aria-label="details"
                        >
                            <VisibilityOutlined />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            ) : (
                <TableCell>
                    <Box sx={{ display: "flex" }}>
                        <Tooltip title="Approve request">
                            <IconButton
                                disabled={isBtnDisabled}
                                onClick={handleApproveClick}
                                color="success"
                                aria-label="approve"
                            >
                                <TaskAltOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reject request">
                            <IconButton
                                disabled={isBtnDisabled}
                                onClick={handleRejectClick}
                                color="error"
                                aria-label="reject"
                            >
                                <NotInterested />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View details">
                            <IconButton
                                onClick={handleView}
                                aria-label="details"
                            >
                                <VisibilityOutlined />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </TableCell>
            )}
        </TableRow>
    )
}
