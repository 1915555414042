import { useDispatch, useSelector } from "react-redux"
import { goBack } from "redux/slices/selfOnboardSlice"

import { Box } from "@mui/material"
import { Button } from "components/common/Ui/Form"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"
import { useFormikContext } from "formik"
import { useSnackbar } from "components/common/snackbar/SnackbarProvider"

const SuitabilityAction = ({ isLoading }) => {
    const dispatch = useDispatch()
    const { activeStep, totalStepsCount } = useSelector(
        (state) => state.selfOnboard
    )
    const { errors } = useFormikContext()
    const { onError } = useSnackbar()

    const handleBackBtn = () => {
        dispatch(goBack())
    }

    const toastError = () => {
        const { is_pep, is_us_indiv, is_accredited_investor } = errors

        if (is_us_indiv) {
            onError(is_us_indiv)
        }

        if (is_pep) {
            onError(is_pep)
        }

        if (is_accredited_investor) {
            onError(is_accredited_investor)
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBackBtn}
                sx={{ mr: 1 }}
                startIcon={NavigateBefore}
            >
                Back
            </Button>
            <Button
                onClick={toastError}
                variant="contained"
                endIcon={NavigateNext}
                type="submit"
                disabled={isLoading}
            >
                {isLoading
                    ? "Saving..."
                    : activeStep === totalStepsCount - 1
                      ? "Finish"
                      : "Next"}
            </Button>
        </Box>
    )
}
export default SuitabilityAction
