import React, { useState } from "react"
import withPermissionsHoc from "../../Layout/PermissionsHoc/PermissionsHoc"
import AdminDashboard from "./AdminDashboardList/dashboardList"
import LazyLoadingHNI from "./HniDashboard/LazyLoadingHNI"
import LazyLoadingRM from "./RmDashboard/LazyLoadingRM"
import ModalWithTable from "../../common/DashboardModal/ModalWithTable"
import { getLoggedInRole, getLoggedInUserInfo } from "constant/helpersUtility"
function Dashboard() {
    const [role] = useState(getLoggedInRole)

    React.useEffect(() => {
        const user = getLoggedInUserInfo()
        localStorage.setItem("entityId", btoa(user?.entityId))
    }, [])

    return (
        <>
            <ModalWithTable />
            {role === "SUPERADMIN" && <AdminDashboard />}
            {role === "RM" && <LazyLoadingRM />}
            {role === "HNI" && <LazyLoadingHNI />}
        </>
    )
}

export default withPermissionsHoc(Dashboard, "Dashboard")
