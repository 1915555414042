import { createSlice } from "@reduxjs/toolkit"

const formatBankDetail = (bankDetail) => ({
    id: bankDetail.id,
    assetName: bankDetail?.assetName,
    category: bankDetail?.primaryCategoryId?.description,
    isLiability: !bankDetail?.assetLiability,
    isInsideKairos: bankDetail?.type === "Inside Kairos",
    isOnline: bankDetail?.synchupType === "Online",
    isLive: bankDetail?.status === "Live",
    dateOfRequest: bankDetail?.dateOfRequest,
    value: bankDetail?.balance
})

const hniProfileSlice = createSlice({
    name: "product",
    initialState: {
        hniDetails: {
            clientPersonal: {
                email: "",
                secondaryEmail: "",
                salutation: {
                    description: ""
                },
                firstName: "",
                lastName: "",
                mobile: "",
                altMobile: "",
                dob: "",
                gender: {
                    description: ""
                },
                countryId: {
                    countryName: ""
                },
                cityId: {
                    name: ""
                },
                stateId: {
                    stateName: ""
                },
                postalCode: "",
                address1: "",
                address2: "",
                address3: "",
                baseLocationId: {
                    countryName: ""
                },
                originId: {
                    description: ""
                },
                highestLevelOfEducation: {
                    description: ""
                }
            },
            clientFinancial: {
                presentAssetAllocation: [],
                takeSurvey: false,
                netWorth: 0,
                sourceOfWealth: [],
                investibleWealth: "",
                riskAppetite: {
                    description: ""
                },
                industryPreference: [],
                financialGoals: [],
                joiningFee: 0,
                subscriptionFee: 0
            },
            clientProfessional: {
                professionType: [],
                industry: [],
                domainSector: [],
                turnover: ""
            },
            clientFamily: {
                isItAHereditaryBuisness: {
                    description: ""
                },
                maritalStatus: {
                    description: ""
                },
                noOfFamilyMembers: 0,
                memberDetails: []
            },
            clientInterest: {
                financialProduct: [],
                lifestyleProduct: [],
                financialService: [],
                lifestyleService: [],
                personality: [],
                currentMemberships: [],
                previousVacations: []
            },
            clientKyc: {
                proofOfIdentity: {
                    proofOfIdentitySelect: {
                        description: ""
                    },
                    idNumber: "",
                    expiryDate: "",
                    name: "",
                    url: ""
                },
                proofOfAddress: {
                    proofOfAddressSelect: {
                        description: ""
                    },
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    name: "",
                    url: ""
                },
                proofOfTaxResidency: {
                    name: "",
                    url: ""
                },
                proofOfWealth: [],
                sourceOfWealth: []
            }
        },
        bankDetails: [],
        finProdCategories: []
    },
    reducers: {
        setHniDetails: (state, action) => {
            state.hniDetails = action.payload
        },
        setFinProdCategories: (state, action) => {
            state.finProdCategories = action.payload
        },
        setBankDetails: (state, action) => {
            state.bankDetails = action.payload?.map(formatBankDetail)
        },
        addBankDetails: (state, action) => {
            const formatted = {
                ...action.payload,
                dateOfRequest: action.payload.date,
                balance: action.payload.amount
            }
            const newRecord = formatBankDetail(formatted)
            state.bankDetails = [newRecord, ...state.bankDetails]
        },
        setFinancialDetails: (state, action) => {
            state.hniDetails.clientFinancial = action.payload
        },
        updateBankDetails: (state, action) => {
            const assetId = action.payload.assetId
            const updatedValue = action.payload.currentValue

            const oldRecord = state.bankDetails.find(
                (bank) => bank.id === assetId
            )
            const updatedRecord = {
                ...oldRecord,
                value: updatedValue
            }

            const newRecords = state.bankDetails.filter(
                (bank) => bank.id !== assetId
            )
            state.bankDetails = [updatedRecord, ...newRecords]
        },
        setPersonalDetails: (state, action) => {
            state.hniDetails.clientPersonal = action.payload
        },
        setEmploymentDetails: (state, action) => {
            state.hniDetails.clientProfessional = action.payload
        }
    }
})
export const {
    setHniDetails,
    setBankDetails,
    addBankDetails,
    setFinProdCategories,
    updateBankDetails,
    setFinancialDetails,
    setPersonalDetails,
    setEmploymentDetails
} = hniProfileSlice.actions

export default hniProfileSlice.reducer
