import React, { useEffect, useState } from "react"
import { Grid, Pagination, Select, MenuItem, Box } from "@mui/material"
import "./CatalogCards.css"
// import Typography from "@mui/material/Typography"
import ProductCard from "../ProductCard/ProductCard"
import ProductCardLinear from "../ProductCard/ProductCardLinear"
import moment from "moment"
import { useSelector } from "react-redux"
import AccordionWrapper from "./AccordionWrapper"

/******************************************************************************************/
/* Component displaying product catalogue in ALL view, with all asset classes displayed   */
/******************************************************************************************/
// Sorting Items
let sortItems = [
    { id: 1, description: "Price: Low to High" },
    { id: 2, description: "Price: High to Low" },
    { id: 3, description: "Latest" }
]

const CardsDisplay = (props) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [dataListOnUI, setDataListOnUI] = useState([])
    const [filterParam, setFilterParam] = useState(sortItems[2].id)
    const isGridDisplay = useSelector((state) => state.nav.isGridProductDisplay)

    useEffect(() => {
        if (props?.datalist) {
            const sortedData = sortData(props.datalist, filterParam)
            setDataListOnUI(sortedData)
        }
    }, [props.datalist, filterParam])
    // Method to sort depends on selection
    const sortData = (data, sortOrder) => {
        let sortedData = [...data]
        if (sortOrder === 1) {
            sortedData.sort(
                (a, b) =>
                    parseFloat(a.sellingPrice) - parseFloat(b.sellingPrice)
            )
        } else if (sortOrder === 2) {
            sortedData.sort(
                (a, b) =>
                    parseFloat(b.sellingPrice) - parseFloat(a.sellingPrice)
            )
        } else if (sortOrder === 3) {
            sortedData.sort(
                (a, b) =>
                    moment(b.updatedAt).format("X") -
                    moment(a.updatedAt).format("X")
            )
        }
        return sortedData
    }

    const handleChangeSort = (event) => {
        setFilterParam(event.target.value)
    }

    const handleChangePage = (event, value) => {
        setCurrentPage(value)
    }

    const localPagination = (page_size, page_number, data) => {
        return data.slice(
            (page_number - 1) * page_size,
            page_number * page_size
        )
    }

    if (props.loading) {
        return <h2>Loading...</h2>
    }

    return (
        <Grid
            sx={{
                mb: 4,
                paddingX: "10px",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <AccordionWrapper defaultExpanded={true} title={props?.header[0].toUpperCase() +
                props?.header.slice(1).toLowerCase()}>
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    {/* Display title */}
                    {/* <Typography sx={{ fontSize: "18px", fontWeight: "500", mb: 2 }}>
                        {props?.header[0].toUpperCase() +
                            props?.header.slice(1).toLowerCase()}
                    </Typography> */}
                    {/* Select Option to sorting */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Select
                            labelId="simple-select-autowidth-label"
                            id="simple-select-autowidth-Catalog"
                            value={filterParam}
                            sx={{
                                fontFamily: "Mona Sans",
                                height: "24px",
                                fontSize: "14px",
                                "& input::placeholder": {
                                    textOverflow: "ellipsis !important",
                                    color: "#363636 !important",
                                    fontFamily: "Mona Sans",
                                    opacity: 1
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px",
                                    border: "0px solid"
                                },
                                "& .MuiInputBase-root": {
                                    fontFamily: "Mona Sans",
                                    background: "#FFFFFF 0% 0% no-repeat padding-box"
                                }
                            }}
                            onChange={handleChangeSort}
                            inputProps={{ "aria-label": "Without label" }}
                        >
                            <MenuItem selected disabled default value="">
                                <em>Sort By</em>
                            </MenuItem>
                            {sortItems.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                {/** Cards (displayed based on grid display selector) */}


                <Grid sx={{ mb: 2 }}>
                    {Array.isArray(dataListOnUI) && (
                        <>
                            {isGridDisplay ? (
                                <Grid
                                    display="flex"
                                    flexDirection="row"
                                    flexWrap="wrap"
                                    width="100%"
                                >
                                    {localPagination(
                                        3,
                                        currentPage,
                                        dataListOnUI
                                    ).map((cardsList, index) => (
                                        <Grid
                                            item
                                            key={index}
                                            id="product-card-container"
                                            display="flex"
                                            flexDirection="row"
                                            gap={2}
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            justifyContent={"space-around"}
                                            sx={{
                                                paddingRight: 2,
                                                paddingBottom: 2,
                                                minWidth: {
                                                    xs: "80%",
                                                    md: "40%",
                                                    lg: "30%"
                                                }
                                            }}
                                        >
                                            <ProductCard product={cardsList} />
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Grid
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                    sx={
                                        dataListOnUI.length > 0
                                            ? {
                                                backgroundColor: "white",
                                                borderRadius: "12px",
                                                paddingY: 1,
                                                border: "1px solid #ebebeb"
                                            }
                                            : null
                                    }
                                >
                                    {localPagination(
                                        3,
                                        currentPage,
                                        dataListOnUI
                                    ).map((cardsList, index) => (
                                        <Grid
                                            key={index}
                                            id={"productCard" + index}
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                            width="100%"
                                            justifyContent={"flex-start"}
                                            sx={{
                                                ":hover": {
                                                    backgroundColor: "#f8f8f8"
                                                },
                                                borderBottom:
                                                    dataListOnUI.length - 1 ===
                                                        index
                                                        ? "none"
                                                        : "1px solid #ebebeb"
                                            }}
                                        >
                                            <ProductCardLinear
                                                product={cardsList}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </>
                    )}
                    {!dataListOnUI.length && (
                        <Grid container spacing={3}>
                            <p className="nodataMessage">
                                No {(props?.header).toLowerCase()} details
                            </p>
                        </Grid>
                    )}
                </Grid>
                {/** Pagination */}
                <Pagination
                    sx={{
                        "& .MuiPaginationItem-root.Mui-selected": {
                            background:
                                "linear-gradient(61deg, #D2C6FC -0.84%, #9FB3F0 83.4%)",
                            color: "white !important"
                        }
                    }}
                    count={Math.ceil(props?.datalist?.length / 3) || 0}
                    page={currentPage}
                    onChange={handleChangePage}
                    style={{ float: "right" }}
                />
            </AccordionWrapper>
        </Grid>
    )
}

export default CardsDisplay
