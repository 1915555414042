import Placeholder from "@tiptap/extension-placeholder"
import { useEditor, EditorContent } from "@tiptap/react"
import Image from "@tiptap/extension-image"
import Highlight from "@tiptap/extension-highlight"
import StarterKit from "@tiptap/starter-kit"
import "./richtextfield.css"
import {
    FormatListNumbered,
    FormatListBulleted,
    InsertPhotoOutlined,
    ColorizeOutlined
} from "@mui/icons-material"
import { Box, Tooltip } from "@mui/material"
import { useEffect, useCallback } from "react"
import Underline from "@tiptap/extension-underline"
import Color from "@tiptap/extension-color"
import TextStyle from "@tiptap/extension-text-style"

const tools = [
    { cmd: "toggleBold", icon: "B", type: "bold", desc: "Bold" },
    { cmd: "toggleUnderline", icon: "U", type: "underline", desc: "Underline" },
    { cmd: "toggleItalic", icon: "I", type: "italic", desc: "Italic" },
    { cmd: "setParagraph", icon: "¶", type: "paragraph", desc: "Paragraph" },
    {
        cmd: "toggleHeading",
        type: "heading",
        variant: [
            {
                level: 1,
                icon: "H1",
                desc: "Heading 1"
            },
            {
                level: 2,
                icon: "H2",
                desc: "Heading 2"
            },
            {
                level: 3,
                icon: "H3",
                desc: "Heading 3"
            },
            {
                level: 4,
                icon: "H4",
                desc: "Heading 4"
            },
            {
                level: 5,
                icon: "H5",
                desc: "Heading 5"
            },
            {
                level: 6,
                icon: "H6",
                desc: "Heading 6"
            }
        ]
    },
    {
        cmd: "toggleHighlight",
        icon: <ColorizeOutlined fontSize="small" />,
        type: "highlight",
        desc: "Highlight text"
    },
    {
        cmd: "toggleBulletList",
        icon: <FormatListBulleted />,
        type: "bulletList",
        desc: "Unordered list"
    },
    {
        cmd: "toggleOrderedList",
        icon: <FormatListNumbered />,
        type: "orderedList",
        desc: "Ordered list"
    }
]
const RichTextField = ({
    value = "",
    setValue,
    placeholder = "Write description..."
}) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Image,
            Highlight,
            Underline,
            Color,
            TextStyle,
            Placeholder.configure({
                placeholder,
                emptyEditorClass: "editor-empty"
            })
        ],
        content: value,
        onUpdate: ({ editor }) => {
            setValue(editor.getHTML())
        }
    })

    // Effect to update editor content when `value` changes
    useEffect(() => {
        if (editor && editor.getHTML() !== value) {
            editor.commands.setContent(value)
        }
    }, [value, editor]) // Run when `value` or `editor` changes

    if (!editor) return null
    return (
        <Box
            sx={{
                borderRadius: "4px",
                border: "1px solid #ddd"
            }}
        >
            <Toolbar editor={editor} />
            <EditorContent editor={editor} />
        </Box>
    )
}

const Toolbar = ({ editor }) => {
    const addImage = useCallback(() => {
        const url = window.prompt("URL")

        if (url) {
            editor.chain().focus().setImage({ src: url }).run()
        }
    }, [editor])

    if (!editor) return null

    return (
        <Box
            sx={{
                p: "4px",
                display: "flex",
                gap: "4px",
                borderBottom: "1px solid #ddd"
            }}
        >
            {tools.map(({ cmd, icon, type, desc, variant }) => {
                return (
                    <>
                        {type === "heading" ? (
                            <>
                                {variant?.map(({ level, icon, desci }) => (
                                    <ToolItem
                                        key={`${type}${level}`}
                                        type={type}
                                        desc={desci}
                                        icon={icon}
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleHeading({ level })
                                                .run()
                                        }
                                        isActive={editor.isActive("heading", {
                                            level
                                        })}
                                    />
                                ))}
                            </>
                        ) : (
                            <ToolItem
                                key={type}
                                type={type}
                                onClick={() =>
                                    editor.chain().focus()[cmd]().run()
                                }
                                disabled={
                                    !editor.can().chain().focus()[cmd]().run()
                                }
                                isActive={editor.isActive(type)}
                                desc={desc}
                                icon={icon}
                            />
                        )}
                    </>
                )
            })}

            <Tooltip title="Text color">
                <Box
                    sx={{
                        width: "28px",
                        cursor: "pointer",
                        height: "28px"
                    }}
                >
                    <input
                        style={{
                            border: "none",
                            width: "100%"
                        }}
                        type="color"
                        onInput={(event) =>
                            editor
                                .chain()
                                .focus()
                                .setColor(event.target.value)
                                .run()
                        }
                        value={editor.getAttributes("textStyle").color}
                        data-testid="setColor"
                    />
                </Box>
            </Tooltip>
            <Tooltip title="Add image">
                <button
                    type="button"
                    key="image"
                    onClick={addImage}
                    className={editor.isActive("image") ? "is-active" : ""}
                    style={{
                        display: "grid",
                        placeContent: "center",
                        width: "28px",
                        height: "28px",
                        border: "none",
                        background: editor.isActive("image")
                            ? "#eee"
                            : "transparent",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "14px",
                        transition: "background 0.2s",
                        fontStyle: "normal"
                    }}
                >
                    <InsertPhotoOutlined />
                </button>
            </Tooltip>
        </Box>
    )
}

const ToolItem = ({ desc, type, onClick, onDisabled, isActive, icon }) => {
    return (
        <Tooltip title={desc}>
            <button
                type="button"
                key={type}
                onClick={onClick}
                disabled={onDisabled}
                className={isActive ? "is-active" : ""}
                style={{
                    display: "grid",
                    placeContent: "center",
                    width: "28px",
                    height: "28px",
                    border: "none",
                    background: isActive ? "#eee" : "transparent",
                    cursor: "pointer",
                    fontSize: "14px",
                    transition: "background 0.2s",
                    fontStyle: type === "italic" ? "italic" :  "normal",
                    textDecoration: type === "underline" ? "underline" : "none"
                }}
            >
                {icon}
            </button>
        </Tooltip>
    )
}

export default RichTextField
