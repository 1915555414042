/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import {
    Autocomplete,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
    Grid,
    TextField
} from "@mui/material"
import { styled } from "@mui/system"

import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { formatDate } from "../../../common/Utils/DateFormat"
import { toast } from "react-toastify"

import {
    setSelectedPrimaryStock,
    setSelectedSecondaryStock,
    setIsCompareCheckboxChecked,
    setStockSearchTypeSelector,
    setStockAnalysisResponse,
    setIsAnalyticsLoading,
    setStockDetails
} from "../../../../redux/slices/ChatGptSlice"

import { store } from '../../../../redux';

export default function SidebarStocks({ queryAPI }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const tickersList = useSelector((state) => state.chatGpt.tickersList)
    const selectedPrimaryStock = useSelector((state) => state.chatGpt.selectedPrimaryStock)
    const selectedSecondaryStock = useSelector((state) => state.chatGpt.selectedSecondaryStock)
    const isCompareCheckboxChecked = useSelector((state) => state.chatGpt.isCompareCheckboxChecked)
    const stockSearchTypeSelector = useSelector((state) => state.chatGpt.stockSearchTypeSelector)
    const isAnalyticsLoading = useSelector((state) => state.chatGpt.isAnalyticsLoading)

    const base_url = "https://gaia.kairoswealth.com/"
    let auth_header = ""
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user")
        if (user) {
            auth_header = `Bearer ${JSON.parse(user).idToken}`
        }
    }
    const headers = {
        "Content-Type": "application/json",
        "Authorization": auth_header,
        "env": process.env.REACT_APP_DOMAIN_NAME,
        "Accept": "application/json",
        "Origin": window.location.origin
    }

    const [searchResults, setSearchResults] = useState([])

    const handlePrimaryTickerChange = (event, newValue) => {
        if (newValue === null) {
            dispatch(setSelectedPrimaryStock(""))
        } else {
            dispatch(setSelectedPrimaryStock(newValue.ticker))
        }
    }

    const handleSecondaryTickerChange = (event, newValue) => {
        if (newValue === null) {
            dispatch(setSelectedSecondaryStock(""))
        } else {
            dispatch(setSelectedSecondaryStock(newValue.ticker))
        }
    }

    const handleCompareChecked = (event) => {
        dispatch(setIsCompareCheckboxChecked(!isCompareCheckboxChecked))
    }

    const fetchStockDetails = async (query_str) => {
        dispatch(setStockDetails({}));
        dispatch(setIsAnalyticsLoading(true));
        dispatch(setStockAnalysisResponse({}));

        let url = base_url + "stock_graph/?ticker=" + selectedPrimaryStock + "&period=daily"
        await fetch(url, {
            method: "GET",
            headers: headers,
            credentials: "include"
        })
            .then((response) => {
                if (!response.ok) {
                    toast.error(
                        "Error while fetching stock details, please contact your admin - error code 1006"
                    )
                    throw new Error("Error while fetching stock details.")
                }
                const json_response = response.json()
                return json_response
            })
            .then((responseData) => {
                console.log("stockDetails responseData", responseData)
                dispatch(setStockDetails(responseData))
                dispatch(setIsAnalyticsLoading(false));
            })
    }

    const fetchDataStockAnalysis = async (query_str) => {
        dispatch(setIsAnalyticsLoading(true));
        dispatch(setStockAnalysisResponse({}));
        dispatch(setStockDetails({}));
        try {
            let raw = ""
            let raw_chart = ""
            if (stockSearchTypeSelector === "analysis") {
                console.log("Analyzing Stock...")
                raw = JSON.stringify({
                    "input": {
                        "messages": [
                            {
                                "content": "Provide a full analysis of the stock having the following ticker: " + selectedPrimaryStock,
                                "type": "human"
                            }
                        ]
                    },
                    "config": {}
                });
                raw_chart = JSON.stringify({
                    "input": {
                        "messages": [
                            {
                                "content": "Please provide a chart analysis of the stock having the following ticker: " + selectedPrimaryStock,
                                "type": "human"
                            }
                        ]
                    }
                });
            } else if (stockSearchTypeSelector === "stockselector") {
                console.log("Scanning Universe...")
                raw = JSON.stringify({
                    "input": {
                        "messages": [
                            {
                                "content": "Please scan the stock universe and provide a summary of the best 3 setups",
                                "type": "human"
                            }
                        ]
                    }
                });
            }

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: raw,
                redirect: "follow"
            };

            // Helper function to handle streaming response
            const handleStream = async (response, streamType) => {
                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    const text = decoder.decode(value, { stream: true });
                    const lines = text.split('\n').filter(line => line.trim());
                    let currentEvent = {};

                    for (const line of lines) {
                        if (line.startsWith('event: ')) {
                            currentEvent.event = line.slice(7);
                        } else if (line.startsWith('data: ')) {
                            try {
                                currentEvent.data = JSON.parse(line.slice(6));
                                // Only dispatch when we have a complete event
                                if (currentEvent.event && currentEvent.data) {
                                    const currentResponse = store.getState().chatGpt.stockAnalysisResponse;
                                    const currentAnswers = currentResponse?.answer || [];

                                    const intermediateAnswer = {
                                        date: formatDate(new Date(), "DD-MMM-YYYY"),
                                        question: query_str,
                                        bookmark: "No",
                                        // Only append the new event
                                        answer: [...currentAnswers, { ...currentEvent, streamType }],
                                        chatGptId: "",
                                        ticker: selectedPrimaryStock || ""
                                    };
                                    dispatch(setStockAnalysisResponse(intermediateAnswer));
                                }
                            } catch (e) {
                                console.log('Error parsing JSON:', e);
                            }
                        }
                    }
                }
            };

            // Make both API calls in parallel
            const [analysisResponse, chartResponse] = await Promise.all([
                fetch(base_url + 'chat/stream_log', { ...requestOptions, body: raw }),
                fetch(base_url + 'chat/stream_log', { ...requestOptions, body: raw_chart })
            ]);

            // Handle both streams in parallel
            await Promise.all([
                handleStream(analysisResponse, 'analysis'),
                handleStream(chartResponse, 'chart')
            ]);

            dispatch(setIsAnalyticsLoading(false));
        } catch (error) {
            dispatch(setIsAnalyticsLoading(false));
            console.error("Error fetching data:", error);
        }
    }

    return (
        <Grid
            width="100%"
            display="flex"
            flexDirection="column"
            sx={{ rowGap: 0 }}
            mb={1}
            paddingX={2}
        >
            <Grid
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                width="100%"
                sx={{ marginBottom: 1, color: "#fff" }}
            >
                <Typography
                    sx={{
                        paddingX: 1,
                        paddingY: 0.5,
                        border: "1px solid gray",
                        borderRadius: "8px 0 0 0px",
                        background: stockSearchTypeSelector === "10k" ? "#6442C4" : "#FFFFFF",
                        color: stockSearchTypeSelector === "10k" ? "#FFFFFF" : "#000000",
                        cursor: "pointer",
                        width: "50%",
                        textAlign: "center"
                    }}
                    onClick={() => dispatch(setStockSearchTypeSelector("10k"))}
                >
                    {t("10K-10Q")}
                </Typography>
                <Typography
                    sx={{
                        paddingX: 1,
                        paddingY: 0.5,
                        border: "1px solid gray",
                        borderRadius: "0 8px 0px 0",
                        background: stockSearchTypeSelector === "analysis" ? "#6442C4" : "#FFFFFF",
                        color: stockSearchTypeSelector === "analysis" ? "#FFFFFF" : "#000000",
                        cursor: "pointer",
                        width: "50%",
                        textAlign: "center"
                    }}
                    onClick={() => dispatch(setStockSearchTypeSelector("analysis"))}
                >
                    {t("Analysis")}
                </Typography>
                <Typography
                    sx={{
                        paddingX: 1,
                        paddingY: 0.5,
                        border: "1px solid #d3d3d3",
                        borderTop: "none",
                        borderRadius: "0px 0px 8px 8px",
                        background: "#e0e0e0",
                        color: "#9e9e9e",
                        // background: stockSearchTypeSelector === "stockselector" ? "#6442C4" : "#FFFFFF",
                        // color: stockSearchTypeSelector === "stockselector" ? "#FFFFFF" : "#000000",
                        // cursor: "pointer",
                        cursor: "not-allowed",
                        width: "100%",
                        textAlign: "center",
                        opacity: 0.7
                    }}
                //onClick={() => dispatch(setStockSearchTypeSelector("stockselector"))}
                >
                    {t("Stock Universe Selector")}
                </Typography>
            </Grid>

            {stockSearchTypeSelector === "10k" && (<>
                <Typography mb={1}>
                    {t("Pick a stock:")}
                </Typography>
                <Autocomplete
                    id="primary-ticker"
                    width="100%"
                    size="small"
                    value={
                        tickersList.find(
                            (ticker) => ticker.ticker === selectedPrimaryStock
                        ) || null
                    }
                    options={tickersList}
                    getOptionLabel={(ticker) =>
                        ticker.ticker + " - " + ticker.company_name
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.ticker === value.ticker
                    }
                    renderInput={(params) => (
                        <TextField {...params} label={t("Ticker")} />
                    )}
                    onChange={handlePrimaryTickerChange}
                />
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox checked={isCompareCheckboxChecked} sx={{}} />
                        }
                        label={t("Compare")}
                        onChange={handleCompareChecked}
                    />
                </FormControl>
                <Autocomplete
                    id="secondary-ticker"
                    width="100%"
                    size="small"
                    value={
                        tickersList.find(
                            (ticker) => ticker.ticker === selectedSecondaryStock
                        ) || null
                    }
                    disabled={!isCompareCheckboxChecked}
                    options={tickersList}
                    getOptionLabel={(ticker) =>
                        ticker.ticker + " - " + ticker.company_name
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.ticker === value.ticker
                    }
                    renderInput={(params) => (
                        <TextField {...params} label={t("Ticker")} />
                    )}
                    onChange={handleSecondaryTickerChange}
                />
            </>)}

            {stockSearchTypeSelector === "stockselector" && (
                <Grid sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                        onClick={() => fetchDataStockAnalysis("Scan Universe")}
                        sx={{
                            marginTop: 1,
                            backgroundColor: '#6F3CE3',
                            color: "#fff",
                            cursor: 'pointer',
                            textAlign: 'center',
                            '&:hover': {
                                backgroundColor: '#5b32bc'
                            },
                            borderRadius: "8px",
                            paddingY: 1
                        }}                    >
                        {t("Scan Universe")}
                    </Typography>
                    {/** TODO Add the stock universe settings cog */}
                </Grid>
            )}

            {stockSearchTypeSelector === "analysis" && (
                <Grid sx={{ display: "flex", flexDirection: "column" }}>
                    <Autocomplete
                        id="stock-analysis-search"
                        freeSolo
                        size="small"
                        options={searchResults || []}
                        getOptionLabel={(option) =>
                            typeof option === 'string'
                                ? option
                                : `${option.symbol} - ${option.name}`
                        }
                        onInputChange={(event, value) => {
                            const searchTerm = value;
                            if (searchTerm && searchTerm.length >= 2) {
                                queryAPI("search_ticker", "GET", `?search_query=${searchTerm}`, "")
                                    .then(data => {
                                        setSearchResults(data?.results || []);
                                    })
                                    .catch(error => {
                                        console.error("Error fetching search results:", error);
                                        setSearchResults([]);
                                    });
                            } else {
                                setSearchResults([]);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Search stocks")}
                            />
                        )}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                dispatch(setSelectedPrimaryStock(
                                    typeof newValue === 'string' ? newValue : newValue.symbol
                                ));
                            }
                        }}
                        filterOptions={(x) => x}
                        noOptionsText="Type to search..."
                        sx={{ width: "100%", mb: 2, mt: 1 }}
                    />
                    <Typography
                        onClick={() => {
                            fetchDataStockAnalysis("Analysis of " + selectedPrimaryStock)
                        }}
                        sx={{
                            backgroundColor: selectedPrimaryStock ? '#6F3CE3' : '#9d86e6',
                            color: "#fff",
                            cursor: selectedPrimaryStock ? 'pointer' : 'not-allowed',
                            textAlign: 'center',
                            '&:hover': {
                                backgroundColor: selectedPrimaryStock ? '#5b32bc' : '#9d86e6'
                            },
                            borderRadius: "8px",
                            paddingY: 1
                        }}
                    >
                        {t("Analyze Stock")}
                    </Typography>
                    <Typography
                        onClick={() => {
                            fetchStockDetails()
                        }}
                        sx={{
                            backgroundColor: selectedPrimaryStock ? '#6F3CE3' : '#9d86e6',
                            color: "#fff",
                            cursor: selectedPrimaryStock ? 'pointer' : 'not-allowed',
                            textAlign: 'center',
                            '&:hover': {
                                backgroundColor: selectedPrimaryStock ? '#5b32bc' : '#9d86e6'
                            },
                            borderRadius: "8px",
                            paddingY: 1,
                            mt: 1
                        }}
                    >
                        {t("Get Stock Details")}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}
