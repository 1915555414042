import { Formik, Form, Field } from "formik"

import * as Yup from "yup"
import FormWrapper from "../../components/FormWrapper"
import RadioGroupWrapper from "./RadioGroupWrapper"
import SectionWrapper from "./SectionWrapper"
import { SelectWrapper } from "components/common/Ui/Form"
import { useDispatch, useSelector } from "react-redux"
import { goToNextIndividualSuitability } from "redux/slices/selfOnboardSlice"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { updateSelfOnboardProspect } from "services/prospectsServices"
import { toast } from "react-toastify"
import SuitabilityAction from "./SuitabilityAction"

const accreditedInvestor = [
    {
        value: "Y",
        label: "Yes",
        subtext: "I am an accredited investor."
    },
    {
        value: "N",
        label: "No",
        subtext: "I am not an accredited investor."
    }
]

const pep = [
    {
        value: "Y",
        label: "Yes",
        subtext: "I am a Politically Exposed Person (PEP)."
    },
    {
        value: "N",
        label: "No",
        subtext: "I am not a Politically Exposed Person (PEP)."
    }
]

// ** US residential
const usPerson = [
    {
        value: "Y",
        label: "Yes",
        subtext: "I am a U.S. individual."
    },
    {
        value: "N",
        label: "No",
        subtext: "I am not a U.S. individual."
    }
]

const INVESTING_TIME_HORIZON = [
    { label: "Less than 1 year", value: "LESS THAN 1 YEAR" },
    { label: "1-3 years", value: "1-3 YEARS" },
    { label: "3-5 years", value: "3-5 YEARS" },
    { label: "More than 5 years", value: "MORE THAN 5 YEARS" }
]
const title = "Suitability details"
const subtitle =
    "To better understand your investor profile and provide relevant recommendations, we need to collect details about your financial status and risk preferences"

const validationSchema = Yup.object({
    is_pep: Yup.string()
        .oneOf(["N"], "Individual must not be politically exposed")
        .required("Individual must not be politically exposed"),

    is_accredited_investor: Yup.string()
        .oneOf(["Y"], "Individual must be accredited investor")
        .required("Individual must be accredited investor"),
    is_us_indiv: Yup.string()
        .oneOf(
            ["N"],
            "We currently do not accept U.S. person. Please contact administrator for any question."
        )
        .required("Please specify whether you are a U.S. person."),
    risk_appetite: Yup.string().required("Risk appetite required"),
    financial_goals: Yup.array()
        .of(Yup.string())
        .required("Financial goals required"),
    industry_preference: Yup.array()
        .of(Yup.string())
        .required("Industry preferences required"),
    investment_duration: Yup.string().required("Investment duration required")
})

export default function IndividualSuitabilityDetails() {
    const { post, isLoading } = useSelfOnboardApi()
    const {
        individualSuitabilityDetails,
        token,
        prospect_id,
        email,
        dropDowns: { industryPreferences, financialGoals, riskAppetites }
    } = useSelector((state) => state.selfOnboard)
    const dispatch = useDispatch()

    const handleNext = async (data) => {
        const { financial_goals, risk_appetite, industry_preference } = data
        const payload = {
            financial_goals,
            risk_appetite,
            industry_preference,
            prospect_id,
            email,
            token
        }

        const resp = await post({
            apiCaller: updateSelfOnboardProspect,
            payload
        })

        if (resp.status === 500) {
            toast.error("Something went wrong. Try contacting support teams")
            return
        }
        dispatch(goToNextIndividualSuitability(data))
    }

    const schemaList = [
        {
            title: "Risk appetite",
            name: "risk_appetite",
            label: "Risk appetite",
            required: true,
            options: riskAppetites || []
        },
        {
            title: "Financial goals",
            name: "financial_goals",
            label: "Financial goals",
            required: true,
            multiple: true,
            options: financialGoals || []
        },
        {
            name: "industry_preference",
            label: "Industry preferences",
            title: "Industry preferences",
            required: true,
            options: industryPreferences || [],

            multiple: true
        },
        {
            title: "What is your most relevant investing time horizon?",
            label: "Investment duration plan",
            options: INVESTING_TIME_HORIZON,
            name: "investment_duration"
        }
    ]

    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={individualSuitabilityDetails}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    <SectionWrapper title="Are you an accredited investor?">
                        <Field
                            name="is_accredited_investor"
                            component={RadioGroupWrapper}
                            options={accreditedInvestor}
                        />
                    </SectionWrapper>
                    <SectionWrapper title="Are you a Politically Exposed Person (PEP)?">
                        <Field
                            name="is_pep"
                            component={RadioGroupWrapper}
                            options={pep}
                        />
                    </SectionWrapper>
                    <SectionWrapper title="Are you a U.S. individual?">
                        <Field
                            name="is_us_indiv"
                            component={RadioGroupWrapper}
                            options={usPerson}
                        />
                    </SectionWrapper>
                    {schemaList.map(
                        ({ name, label, multiple, options, title }) => (
                            <SectionWrapper key={name} title={title}>
                                <SelectWrapper
                                    name={name}
                                    options={options}
                                    required
                                    size="normal"
                                    label={label}
                                    multiple={multiple}
                                />
                            </SectionWrapper>
                        )
                    )}
                    <SuitabilityAction isLoading={isLoading} />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
