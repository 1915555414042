import { useState } from "react"
import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    TableRow
} from "@mui/material"
import TableRowWrapper from "./TableRowWrapper.jsx"
import { NoRecords } from "components/common/Ui/NoRecords"
import { getLoggedInUserInfo } from "constant/helpersUtility.js"

export default function TaskList({
    records = [],
    isLoading,
    handleApprove,
    handleReject
}) {
    const [user] = useState(getLoggedInUserInfo)
    const role = user?.userTypeId?.description?.toLowerCase()
    const isKairosAdmin = role === "kairos admin"
    const hasComplianceRole =
        user?.userSubtypeId?.description?.toUpperCase() === "COMPLIANCE"

    // ** if compliance -> compliance approval enabled, if managment -> managment approval enabled
    const statusToBeDisabled = hasComplianceRole
        ? "PENDING FOR MANAGEMENT APPROVAL"
        : "PENDING FOR COMPLIANCE APPROVAL"

    return (
        <>
            <TableContainer sx={{ maxHeight: 400 }}>
                {records.length > 0 ? (
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                {isKairosAdmin && <TableCell>Entity</TableCell>}
                                <TableCell>Approval Type</TableCell>
                                <TableCell
                                    sx={{
                                        display: {
                                            xs: "none",
                                            lg: "table-cell"
                                        }
                                    }}
                                >
                                    Details
                                </TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.map(
                                ({
                                    entityName,
                                    senderName,
                                    createdAt,
                                    message,
                                    typeOfApproval,
                                    id,
                                    messageType,
                                    approverName,
                                    path,
                                    requestId
                                }) => (
                                    <TableRowWrapper
                                        status={messageType}
                                        key={id}
                                        sender={senderName}
                                        message={message}
                                        entity={entityName}
                                        approvedBy={approverName}
                                        approvalType={typeOfApproval}
                                        createdAt={createdAt}
                                        isKairosAdmin={isKairosAdmin}
                                        viewPath={path}
                                        handleApproveClick={(e) => {
                                            handleApprove(
                                                e,
                                                requestId,
                                                path,
                                                messageType ===
                                                    "Pending for Management Approval"
                                                    ? "management-approve-or-reject"
                                                    : "compliance-approve-or-reject"
                                            )
                                        }}
                                        handleRejectClick={() => {
                                            handleReject(
                                                requestId,
                                                path,
                                                messageType
                                            )
                                        }}
                                        hasComplianceRole={hasComplianceRole}
                                        isBtnDisabled={
                                            messageType?.toUpperCase() ===
                                                statusToBeDisabled || isLoading
                                        }
                                    />
                                )
                            )}
                        </TableBody>
                    </Table>
                ) : (
                    <NoRecords />
                )}
            </TableContainer>
        </>
    )
}
